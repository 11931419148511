import React from 'react';
import { useNavigate } from "react-router-dom";

import * as C from './styled'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';


export const Chat = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/Login');
    }

    return (
        <C.Container>
            <C.Header>
                <ChatBubbleRoundedIcon fontSize="large"  style={{marginRight: "7px"}}/>
                <h2>| Chat</h2>
            </C.Header>

        </C.Container>
    );
}
