import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';




/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const Integration = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [interList, setInterList] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [alertError, setAlertError] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [cert, setCert] = useState('')
    const [key, setKey] = useState('')
    const [integration, setIntegration] = useState('')




    const [isValid, setIsValid] = useState('')
    const [managerField, setManagerField] = useState(false)



    /// DATATABLE STATES /////////////////////////////////////////////////////////

    const [filteredList, setFilteredList] = useState([])
    const [order, setOrder] = useState('')
    const [activeOrder, setActiveOrder] = useState('')
    

    
    /// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase()
      let newArray = list.filter((data) => {
          return data.bco_compe.toLowerCase().search(value) !== -1 
              || data.bco_nome_banco.toLowerCase().search(value) !== -1 
              || data.bco_ispb.toLowerCase().search(value) !== -1 
          })
      setFilteredList(newArray);
    }

    const OrderClick = (field) => {
      setActiveOrder(field)
      let result = filteredList.sort(function(a, b){
        if (order === 'asc'){
          return a[field] < b[field] ? -1 : true
        } else {
          return a[field] > b[field] ? -1 : true
        }
      })
      order === 'asc' ? setOrder('desc') : setOrder('asc')
      setFilteredList(result)
    }
    
    

    /// FUNCTIONS ////////////////////////////////////////////////////////////////

    const AddClick = () => {
      setInterList(list.filter(qitem => qitem.int_nome === 'inter'))
      setModalType(1)
      setModal(true)
    }  
  
    const EditClick = (key) => {
      setSelectedKey([key])
      setModalType(2)
      setModal(true)
    }
  
    const DelClick = (key) => {
      setSelectedKey([key])
      setModalType(3)
      setModal(true)
    }
  
    const ActClick = (key) => {
      setSelectedKey([key])
      setModalType(4)
      setModal(true)
    }
  
  
    const CloseModalClick = () => {
      setSelectedKey('')
      setAlertError('')
      setModal(false)
      setIntegration('')
      reset()
      GetIntegration()
    }
  
      const CloseModalClick2 = () => {
      setSelectedKey('')
      setAlertError('')
      setModal(false)
      setModalType('')
      reset()
    }


    const GetIntegration = async () => {
      let res = await Api.getIntegration();
      setList(res)
    }

    const DelIntegration = async (id) => {
      let res = await Api.delIntegration(id);
      GetIntegration()
    }
  
    const ActBank = async (id, estado) => {
      let res = await Api.upgBank({bco_id: id, bco_estado: estado})
    }
    

    const onSubmit = async (data) => {
        let res2 = await Api.uploadFile(cert)
        let res3 = await Api.uploadFile(key)
        data["int_nome"] = integration 
        data["int_cert"] = cert.name
        data["int_key"] = key.name
        data["int_image"] = integration+'.png'
        let res = await Api.addIntegration(data)
        CloseModalClick()

    };

    const TokenInter = async (id) => {
      let res = await Api.tokenInter()
      if (res.access_token) {
        let res = await Api.addIntegration({int_id: id, int_status: 1})
      } else {
        let res = await Api.addIntegration({int_id: id, int_status: 0})
      }
      GetIntegration()

  };


/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

document.onkeydown = function (e) {
  if (e.key === 'Escape') {
    CloseModalClick();
  }
}



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{
    GetIntegration();
  }, [state]);


/// CONSOLE LOG ////////////////////////////////////////////////////////////////





    return (
        <G.Container>

<      G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <IntegrationInstructionsIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
          <G.MainTitle>Integração</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>
      </G.Header>
          <G.Body style={{flexWrap: "wrap", flexDirection: 'row'}}>
            {list.map((field , index) => (
            <G.Card style={{width: '400px'}}>
              <G.CardHeader  style={{ justifyContent: 'space-between', backgroundColor: '#e0e0e0'}} > 
                <G.SmallTitle>{field.int_nome}</G.SmallTitle>
                {field.int_status !==  1 &&
                <G.Button style={{backgroundColor: 'green'}} onClick={() => {TokenInter(field.int_id)}}>Validar</G.Button>
                }
              </G.CardHeader>
              <G.CardBody style={{justifyContent: 'space-around'}}>
                <G.Image src={'/'+field.int_image} style={{ height: '80px', borderRadius: '10%', border: '3px solid #ccc' }}/>
                <G.OptionsArea style={{flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start'}}>
                  <G.Text>{field.int_client_id}</G.Text>
                  <G.Text>{field.int_client_secret}</G.Text>
                  <G.Text>{field.int_grant_type}</G.Text>
                  <G.Text>{field.int_scope}</G.Text>
                </G.OptionsArea>
              </G.CardBody>
              <G.CardFooter  >
                <G.OptionsArea style={{justifyContent: 'space-between', padding: '7px', backgroundColor: '#F5FFFA', borderRadius: '7px', border: '1px solid #ccc', width: '95%'}}>
                <G.DistacInfoText>
                {field.int_status !==  null &&
                  <>
                  <CheckCircleOutlineIcon style={{color: field.int_status === 1 ? 'green' : 'red', marginRight: '5px'}}/>{field.int_status === 1 ? 'Válido' : 'Inválido'}
                  </>
                  }
                  </G.DistacInfoText>
                <DeleteIcon onClick={() => {DelIntegration(field.int_id)}}/>
                </G.OptionsArea>
              </G.CardFooter>
            </G.Card>
            ))}
          </G.Body>

          <G.Footer>
            <CopyrightIcon/>
              <p> Copyright <CopyrightIcon style={{ fontSize: '12px' }} /> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p>
          </G.Footer>

          {modal !== false &&
            <M.ModalContainer>
              <M.ModalContentSmall style={{}}>
                <M.ModalHeader style={{backgroundColor: '#ffb400', justifyContent: 'space-between' }}>
                  <C.ModalTitle>{integration}</C.ModalTitle>
                    <G.InfoArea>
                      <HighlightOffIcon onClick={CloseModalClick} style={{ color: '#333', cursor: 'pointer' }} fontSize="large" />
                    </G.InfoArea>
                </M.ModalHeader>
                <M.ModalBody style={{ flexDirection: 'column'}}>
                  {integration === '' &&
                    <G.BoxBody style={{flexDirection: 'column', alignItems: 'center'}}>
                      <G.OptionsArea onClick={() => interList[0]?.int_status === 1 ? null : setIntegration('inter')} style={{boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px', backgroundColor: interList[0]?.int_status === 1 ? 'rgba(0, 0, 0, 0.2)'  : '#f9f9f9', width: '90%', padding: '10px', borderRadius: '5px', cursor: 'pointer', marginBottom: '30px', justifyContent: 'space-between'}}>
                       <G.DistacInfoText>
                       <G.Image src={'/inter.png'} style={{ height: '40px', borderRadius: '10%', marginRight: '10px' }}/>
                            Banco Inter
                       </G.DistacInfoText>
                       <AddBoxIcon style={{marginRight: '20px', color: '#2E8B57'}}/>
                      </G.OptionsArea>
                      <G.OptionsArea style={{boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px', backgroundColor: '#f9f9f9', width: '90%', padding: '10px', borderRadius: '5px', cursor: 'pointer', marginBottom: '30px', justifyContent: 'space-between'}}>
                       <G.DistacInfoText>
                       <G.Image src={'/nfse.png'} style={{ height: '40px', borderRadius: '10%', marginRight: '10px' }}/>
                            Nota Fiscal Eletrônica (Serviços)
                       </G.DistacInfoText>
                       <AddBoxIcon style={{marginRight: '20px', color: '#2E8B57'}}/>
                      </G.OptionsArea>
                      <G.OptionsArea style={{boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px', backgroundColor: '#f9f9f9', width: '90%', padding: '10px', borderRadius: '5px', cursor: 'pointer', marginBottom: '30px', justifyContent: 'space-between'}}>
                       <G.DistacInfoText>
                       <G.Image src={'/whatsapp.webp'} style={{ height: '40px', borderRadius: '10%', marginRight: '10px' }}/>
                            Whatsapp
                       </G.DistacInfoText>
                       <AddBoxIcon style={{marginRight: '20px', color: '#2E8B57'}}/>
                      </G.OptionsArea>
                    </G.BoxBody>
                    }
                    {integration === 'inter' &&
                    <G.BoxBody style={{ flexDirection: 'column'}}>
                      <G.FormInputArea  >
                        <G.FormInputLabel>
                         client_id
                        </G.FormInputLabel>
                        <C.Input {...register("int_client_id")} autoFocus/>
                      </G.FormInputArea>
                      <G.FormInputArea>
                        <G.FormInputLabel>
                        client_secret
                        </G.FormInputLabel>
                        <C.Input {...register("int_client_secret")} />
                      </G.FormInputArea>
                      <G.FormInputArea>
                      <G.FormInputLabel>
                        grant_type
                        </G.FormInputLabel>
                        <C.Input {...register("int_grant_type")} />
                      </G.FormInputArea>
                      <G.FormInputArea>
                        <G.FormInputLabel>
                          scope
                        </G.FormInputLabel>
                        <C.Input {...register("int_scope")} />
                      </G.FormInputArea>
                      <G.FormInputArea >
                        <G.FormInputLabel>
                          Certificado(.crt)
                        </G.FormInputLabel>
                        <C.Input style={{backgroundColor:'#e9e9e9', padding: '10px'}} type="file" onChange={e => setCert(e.target.files[0])}  accept=".crt"/>
                      </G.FormInputArea>
                      <G.FormInputArea >
                        <G.FormInputLabel>
                          Chave(.key)
                        </G.FormInputLabel>
                        <C.Input style={{backgroundColor:'#e9e9e9', padding: '10px'}} type="file" onChange={e => setKey(e.target.files[0])}  accept=".key"/>
                      </G.FormInputArea>
                    </G.BoxBody>
                    }
                </M.ModalBody>
                {integration !== '' &&
                <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </M.ModalFooter>
              }
              </M.ModalContentSmall>
            </M.ModalContainer>
            }
        </G.Container>

    );
}
