import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import BlockIcon from '@mui/icons-material/Block';
import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FactoryIcon from '@mui/icons-material/Factory';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const Companies = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [alertError, setAlertError] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)
    
    const [managerField, setManagerField] = useState(false)

    const [stateErrors, setStateErros] = useState(false);



/// MASK ///////////////////////////////////////////////////////////////////////

  const [cnpjNumber, setCnpjNumber] = useState('')

  const onChangeCnpj = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99.999.999/9999-99']);

    setCnpjNumber(unMask(maskedValue));

  }


  const [cepNumber, setCepNumber] = useState('')

  const onChangeCep = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99999-999']);

    setCepNumber(unMask(maskedValue));

  }
  

  const [phoneNumber, setPhoneNumber] = useState('')

  const onChangePhone = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999','(99) 9 9999-9999']);

    setPhoneNumber(unMask(maskedValue));

  }


////////////////////////////////////////////////////////////////////////////////



/// DATATABLE STATES /////////////////////////////////////////////////////////

    const [filteredList, setFilteredList] = useState([])
    const [order, setOrder] = useState('')
    const [activeOrder, setActiveOrder] = useState('')

    
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase()
      let newArray = list.filter((data) => {
          return data.emprs_cnpj.toLowerCase().search(value) !== -1 
              || data.emprs_nome.toLowerCase().search(value) !== -1 
              || data.emprs_cidade.toLowerCase().search(value) !== -1 
              || data.emprs_uf.toLowerCase().search(value) !== -1 
              || data.emprs_telefone.toLowerCase().search(value) !== -1 
          })
      setFilteredList(newArray);
    }

    const OrderClick = (field) => {
      setActiveOrder(field)
      let result = filteredList.sort(function(a, b){
        if (order === 'asc'){
          return a[field] < b[field] ? -1 : true
        } else {
          return a[field] > b[field] ? -1 : true
        }
      })
      order === 'asc' ? setOrder('desc') : setOrder('asc')
      setFilteredList(result)
    }


/// FUNCTIONS ////////////////////////////////////////////////////////////////

    const AddClick = () => {
      setModalType(1)
      setModal(true)
    }  

    const EditClick = (key) => {
      setSelectedKey([key])
      setCnpjNumber((filteredList[key].emprs_cnpj === null || filteredList[key].emprs_cnpj === undefined) ? '' : filteredList[key].emprs_cnpj)
        console.log(filteredList[key].emprs_cnpj)
      setCepNumber((filteredList[key].emprs_cep === null || filteredList[key].emprs_cep === undefined) ? '' : filteredList[key].emprs_cep)
        console.log(filteredList[key].emprs_cep)
      setPhoneNumber((filteredList[key].emprs_telefone === null || filteredList[key].emprs_telefone === undefined) ? '' : filteredList[key].emprs_telefone)
        console.log(filteredList[key].emprs_telefone)
      setModalType(2)
      setModal(true)
    }

    const DelClick = (key) => {
      setSelectedKey([key])
      setModalType(3)
      setModal(true)
    }

    const ActClick = (key) => {
      setSelectedKey([key])
      setModalType(4)
      setModal(true)
    }


    const CloseModalClick = () => {
      setLoading(true)
      setSelectedKey('')
      setCnpjNumber('')
      setCepNumber('')
      setPhoneNumber('')
      setAlertError('')
      GetCompany()
      setModal(false)
      setModalType('')
      reset()
      setLoading(false)
    }

      const CloseModalClick2 = () => {
      setLoading(true)
      setSelectedKey('')
      setCnpjNumber('')
      setCepNumber('')
      setPhoneNumber('')
      setAlertError('')
      setModal(false)
      setModalType('')
      reset()
      setLoading(false)
    }


    const GetCompany = async () => {
      setLoading(true)
      let res = await Api.getCompany();
      setList(res)
      setFilteredList(res)
      setLoading(false)
    }

    const DelCompany = async (id) => {
      let res = await Api.delCompany(id);
      GetCompany()
    }


    // const ActCompany = async (id, estado) => {
    //   let res = await Api.upgCompany({emprs_id: id, emprs_estado: estado})
    //   console.log("ActCompany > res: ", res)
    //   GetCompany()
    // }


    const ActCompany = async (id, estado) => {
      const data = ({
        emprs_id: id, 
        emprs_estado: estado, 
        emprs_usuario_atualizacao: user.id
      })
      let res = await Api.upgCompany(data)
      //console.log("ActCompany > res : ", res)
      GetCompany()
    }


    // const onSubmit = async (data) => {
    //   data["emprs_cnpj"] = cnpjNumber;
    //   data["emprs_cep"] = cepNumber;
    //   data["emprs_telefone"] = phoneNumber;
    //   console.log(data)

    //   if (cnpjNumber === null || cnpjNumber === '' 
    //       || cepNumber === null || cepNumber === ''
    //       || phoneNumber === null || phoneNumber === ''
    //   ) {
    //     setStateErros(true)
    //   } else {
    //     setStateErros(false)

    //   console.log(data)

    //   if (modalType === 1) {
    //     let res1 = await Api.addCompany(data)
    //     console.log("onSubmit > add > res1", res1)
    //   }
    //   if (modalType === 2) {
    //     let res2 = await Api.upgCompany(data)
    //     console.log("onSubmit > upg > res2", res2)
    //   }

    //   CloseModalClick()
    //   GetCompany()

    //   }
    // };


    const onSubmit = async (data) => {
      data["emprs_cnpj"] = cnpjNumber;
      data["emprs_cep"] = cepNumber;
      data["emprs_telefone"] = phoneNumber;
      //console.log(data)

      if (cnpjNumber === null || cnpjNumber === '' 
          || cepNumber === null || cepNumber === ''
          || phoneNumber === null || phoneNumber === ''
      ) {
        setStateErros(true)
      } else {
        setStateErros(false)

        switch (modalType) {
          case 1:
            const userAdd = ({
              emprs_usuario_criacao: user.id,
              emprs_usuario_atualizacao: user.id
            })
            const add = ({...data, ...userAdd})
            let res1 = await Api.addCompany(add)
            //console.log("addCompany > res1 : ", res1)
            break;

          case 2:
            const userUpg = ({
              emprs_usuario_atualizacao: user.id
            })
            const upg = ({...data, ...userUpg})
            let res2 = await Api.upgCompany(upg)
            //console.log("upgCompany > res2 : ", res2)
            break;

          default:
            break;
        }

        console.log(data)

      // if (modalType === 1) {
      //   let res1 = await Api.addCompany(data)
      //   console.log("onSubmit > add > res1", res1)
      // }
      // if (modalType === 2) {
      //   let res2 = await Api.upgCompany(data)
      //   console.log("onSubmit > upg > res2", res2)
      // }

      CloseModalClick()
      GetCompany()

      }
    };



/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

    document.onkeydown = function (e) {
      if (e.key === 'Escape') {
        CloseModalClick();
      }
    }



/// USE EFECT //////////////////////////////////////////////////////////////////

    useEffect(()=>{
      GetCompany();
    }, [state]);


    
/// CONSOLE LOG ////////////////////////////////////////////////////////////////

  // console.log("CPJ: ", cnpjNumber)
  // console.log("CEP: ", cepNumber)
  // console.log("TEL: ", phoneNumber)
  // console.log("filteredList: ", filteredList)







    return (
        <G.Container>

          {/* <G.Header>
            <G.HeaderTitleArea>
              <AppRegistrationOutlinedIcon fontSize='large' style={{ marginRight: "7px" }} />
              <G.MainTitle>| Cadastro</G.MainTitle>
            </G.HeaderTitleArea>
          </G.Header> */}

          <G.Header>
            <G.HeaderTitleArea style={{ width: '240px' }}>
              <BusinessIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
              <G.MainTitle>Empresas</G.MainTitle>
            </G.HeaderTitleArea>
            <G.HeaderTitleArea style={{ width: '320px' }}>
              
            </G.HeaderTitleArea>
            <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
              <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
                <G.SearchIconArea>
                  <SearchIcon />
                </G.SearchIconArea>
                <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
              </G.SearchArea>        
            </G.HeaderTitleArea>
            <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
              <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
                <AddIcon fontSize='small'/>
                {'Adicionar'}
              </G.Button>
            </G.HeaderTitleArea>
          </G.Header>

          <G.Body>

            {loading &&  
              <G.Logo src={"/loading.svg"} />
            }

            {(!loading && filteredList.length === 0) &&
              <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
                <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
                <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
                  Não existem itens para a seleção atual! 
                </G.MainTitle>
              </G.OptionsArea>
            }

            {(!loading && filteredList.length > 0)  && 
              <>
                <G.Box>

                  <G.TableHeader>
                    <G.OptionsArea style={{ padding: '0px' }}>
                      <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                      <G.FieldArea size={'150px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('emprs_cnpj')}><G.DistacInfoText>CNPJ</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'emprs_cnpj') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'240px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('emprs_nome')}><G.DistacInfoText>Empresa</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'emprs_nome') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'170px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('emprs_cidade')}><G.DistacInfoText>Cidade</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'emprs_cidade') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'80px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('emprs_uf')}><G.DistacInfoText>UF</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'emprs_uf') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'130px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('emprs_telefone')}><G.DistacInfoText>Telefone</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'emprs_telefone') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                    </G.OptionsArea>
                  </G.TableHeader>

                  {(loading && filteredList.length === 0 ) &&  
                    <G.Loading2 src={"/loading.svg"} />
                  }

                  <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                    {(loading && filteredList.length === 0) && 
                      <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                        *** Não existem itens a exibir. ***
                      </G.Text>
                    }

                    {(!loading && filteredList.length > 0) &&
                      <>
                        {filteredList.map((item, key) => (
                          <G.TableArea key={key}>
                            <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border:  item.emprs_estado === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}>
                              <BusinessIcon style={{fontSize: '22px', color: '#000'}}/>
                            </C.ContactTypeIconArea></G.Text></G.FieldArea>
                            <G.FieldArea size={'150px'}>
                              <G.Text>{item.emprs_cnpj === null ? '' : mask(item.emprs_cnpj, ['999.999.999/9999-99'])}</G.Text>
                            </G.FieldArea>
                            <G.FieldArea size={'240px'}><G.Text>{item.emprs_nome}</G.Text></G.FieldArea>
                            <G.FieldArea size={'170px'}><G.Text>{item.emprs_cidade}</G.Text></G.FieldArea>
                            <G.FieldArea size={'80px'}><G.Text>{item.emprs_uf}</G.Text></G.FieldArea>
                            <G.FieldArea size={'130px'}>
                              <G.Text>{item.emprs_telefone === null ? '' : mask(item.emprs_telefone, ['(99) 9999-9999', '(99) 9 9999-9999'])}</G.Text>
                            </G.FieldArea>
                            <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                              <G.IconButton onClick={() => EditClick(key)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                              <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.emprs_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.emprs_estado === 0 ? <CheckIcon fontSize="small"/> : item.emprs_estado === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}</G.IconButton>
                              <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                            </G.FieldArea>
                          </G.TableArea>
                        ))}
                      </>
                    }
                  </G.TableBody>

                </G.Box>
              </>
            }
          </G.Body>


          <G.Footer style={{ color: '#333', fontSize: '14px' }}>
            {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
          </G.Footer>


            {modal !== false &&
              <M.ModalContainer>
                <M.ModalContentSmall style={{ maxHeight: '600px' }}>

                  <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].emprs_estado === 0 ? '#BDF5D6' : '#ccc'), justifyContent: 'space-between', bordeRadius: '15px' }}>
                    <C.ModalTitle>{modalType === 1 ? 'Adicionar Empresa' : modalType === 2 ? 'Editar Empresa' : modalType === 3 ? 'Excluir Empresa' : (filteredList[selectedKey].emprs_estado === 0 ? 'Ativar Empresa' : 'Desativar Empresa')}</C.ModalTitle>
                    <G.InfoArea>
                      <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                    </G.InfoArea>
                  </M.ModalHeader>

                  <M.ModalBody style={{flexDirection: 'column', alignItems: 'center' }}>
                    
                    {(modalType === 2 || modalType === 3) && 
                      <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_id} {...register("emprs_id")} />
                    }

                    {(modalType === 1 || modalType === 2) &&
                      <G.BoxBodyScroll style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>

                        <G.FormInputArea>
                          <G.FormInputLabel>
                            CNPJ
                          </G.FormInputLabel>
                          <G.FormInput 
                            type='text'
                            name='cnpjNumber'
                            placeholder=''
                            value={mask(cnpjNumber, ['99.999.999/9999-99'])}
                            onChange={onChangeCnpj}
                            //autoFocus={(Object.keys(errors).length !== 0 || stateErrors) ? false : true} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_cnpj} {...register("emprs_cnpj", { required: true })} 
                          />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            IE
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_ie} {...register("emprs_ie", { required: true })} />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            Nome
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_nome} {...register("emprs_nome", { required: true })} />
                        </G.FormInputArea>

                        <G.FormInputArea>
                          <G.FormInputLabel>
                            Telefone
                          </G.FormInputLabel>
                          <G.FormInput 
                            type='text'
                            name='phoneNumber'
                            placeholder=''
                            value={mask(phoneNumber, ['(99) 9999-9999','(99) 9 9999-9999'])}
                            onChange={onChangePhone}
                            //defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_telefone} {...register("emprs_telefone", { required: true })} 
                          />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            Ramal
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_ramal} {...register("emprs_ramal", { required: true })} />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            E-mail
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_email} {...register("emprs_email", { required: true })} />
                        </G.FormInputArea>

                        <G.FormInputArea>
                          <G.FormInputLabel>
                            Endereço
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_endereco} {...register("emprs_endereco", { required: true })} />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            Bairro
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_bairro} {...register("emprs_bairro", { required: true })} />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            CEP
                          </G.FormInputLabel>
                          <G.FormInput 
                            type='text'
                            name='cepNumber'
                            placeholder=''
                            value={mask(cepNumber, ['99999-999'])}
                            onChange={onChangeCep}
                            //defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_cep} {...register("emprs_cep", { required: true })} 
                          />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            Cidade
                          </G.FormInputLabel>
                          <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_cidade} {...register("emprs_cidade", { required: true })} />
                        </G.FormInputArea>
                        <G.FormInputArea>
                          <G.FormInputLabel>
                            UF
                          </G.FormInputLabel>
                          <G.FormInput maxLength={2} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].emprs_uf} {...register("emprs_uf", { required: true }, { setValueAs: v => v.toUpperCase() })} />
                        </G.FormInputArea>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          {(Object.keys(errors).length > 0 || stateErrors) &&  
                            <div role="alert" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '72%', padding: '7px', margin: '16px 0px 0px 0px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                              <WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} />
                              {'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}
                            </div>
                          }
                        </div>

                      </G.BoxBodyScroll>
                    }
                  </M.ModalBody>

                  {(modalType === 3) && 
                    <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                      <G.Text style={{ margin: '10px 0px' }}>
                        Deseja excluir a Empresa:
                      </G.Text>
                      <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                        <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                          {filteredList[selectedKey].emprs_nome}
                        </G.FormInputLabel>
                      </G.FormInputArea>
                    </M.ModalBody>
                  }

                  {(modalType === 4) && 
                    <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                      <G.Text style={{ margin: '10px 0px' }}>
                        Deseja {filteredList[selectedKey].emprs_estado === 0 ? 'ativar' : 'desativar'} a Empresa:
                      </G.Text>
                      <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                        <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                          {filteredList[selectedKey].emprs_nome}
                        </G.FormInputLabel>
                      </G.FormInputArea>
                    </M.ModalBody>
                  }

                  <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    {alertError !== '' &&
                      <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
                    }
                    
                    {(modalType === 1 || modalType === 2) &&
                      // <G.Button onClick={SendUsers} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                      // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                      <>
                        <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                        <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                      </>
                    }

                    {modalType === 3 && 
                      <>
                        <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                        <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelCompany(filteredList[selectedKey].emprs_id) + CloseModalClick()}>Sim</G.Button>
                      </>
                    }
                    {modalType === 4 && 
                      <>
                        <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                        <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActCompany(filteredList[selectedKey].emprs_id, filteredList[selectedKey].emprs_estado === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                      </>
                    }
                  </M.ModalFooter>

                </M.ModalContentSmall>
              </M.ModalContainer>
            }
        </G.Container>

    );
}
