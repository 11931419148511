import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from "moment";
import 'moment/locale/pt-br';


import Api from '../../services/api'

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'


import { UserContext } from '../../contexts/UserContext';
import { ModalAlert } from '../../components/ModalAlert'

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LockIcon from '@mui/icons-material/Lock';
import TaskIcon from '@mui/icons-material/Task';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CategoryIcon from '@mui/icons-material/Category';

import BadgeIcon from '@mui/icons-material/Badge';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ConstructionIcon from '@mui/icons-material/Construction';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { borderRadius, fontSize, fontWeight, margin, padding, width } from '@mui/system';



export const MainHeader = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, reset, formState: { errors }} = useForm({ mode: 'resolver' });
  
  const { state: user } = useContext(UserContext)
  const { dispatch } = useContext(UserContext);

  moment.locale('pt-br')

  const [step, setStep] = useState(1);
  const [modal, setModal] = useState(false)
  const [subMenuCad, setSubMenuCad] = useState(false)
  const [subMenuFin, setSubMenuFin] = useState(false)
  const [subMenuOth, setSubMenuOth] = useState(false)

  const [type, setType] = useState('')
  const [companies, setCompanies] = useState([])
  const [lawyers, setLawyers] = useState([])
  const [users, setUsers] = useState([])
  const [phases, setPhases] = useState([])
  const [selectedPhase, setSelectedPhase] = useState([])
  const [activityPhase, setActivityPhase] = useState([])

  const [modalAlert, setModalAlert] = useState('')
  const [modalAlertColor, setModalAlertColor] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [file, setFile] = useState(false)

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([])
  const [tokens, setTokens] = useState([])

  const [faseEntrada, setFaseEntrada] = useState('');
  const [fasePrazo, setFasePrazo] = useState('');

  const date = new Date();
  const today = moment().format("YYYY-MM-DD")
  
  const messageEl = useRef('Chat');



  const MenuItemClick = (destination) => {
    navigate(destination);
  }

  const StepClick = (stepPage, type) => {
    setStep(stepPage)
    setType(type) 
  }

  const ModalClick = () => {
    setModal(true);
  }

  const CloseModalClick = () => {
    setModal(false)
    setStep(1)
    setActivityPhase('')
    setProcessNumber('');
  }


/// PROCESS NUMBER MASK ////////////////////////////////////////////////////////

const [processNumber, setProcessNumber] = useState('')

const onChange = ev => {
  
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['9999999-99.9999.9.99.9999']);

  setProcessNumber(unMask(maskedValue));


}

////////////////////////////////////////////////////////////////////////////////
    
  const onSubmitStep= async () => {
    setStep(3)
  }

  const onSubmit = async (data) => {

    data["proc_numero"] = processNumber
    //console.log("onSubmit > data : ", data)
    let res1 = await Api.addProcess(data)
    //console.log("addProcess > res1 : ", res1)
    let process = res1.toString()
    //console.log("addProcess > process : ", process)
    
    let res2 = await Api.addProcessPhase(
      {
        "fase_proc_processo_id": process, 
        "fase_proc_fase_id": selectedPhase,
        "fase_proc_data_entrada": faseEntrada,
        "fase_proc_data_pazo": fasePrazo
      })
    //console.log("addProcessPhase > res2 : ", res2)
    let processPhase = res2.toString()
    //console.log("addProcessPhase > processPhase : ", processPhase)

    let atvsData = activityPhase.map(i => ({...i, ctrl_atvd_fase_id: processPhase})) 
    //console.log("atvsData : ", atvsData)
    let res3 = await Api.addCtrlActivity(atvsData)
    //console.log("addCtrlActivity > res3 : ", res3)

    setModal(false)
    setStep(1)
    setActivityPhase('')
    
    //window.location.reload(false)

  };


  const GetSelect =  async (value) => {
    if (value === 1) {
      let res = await Api.getCompany()
      setCompanies(res)
    } else if (value === 2) {
      let res = await Api.getLawyer()
      setLawyers(res)
    } else if (value === 3)  {
      let res = await Api.getUser()
      setUsers(res)
    } else {
      let res = await Api.getPhase()
      setPhases(res)
    }
  }

  const ActivityPhase =  async (idFase) => {
    setSelectedPhase(idFase)
      console.log("ActivityPhase > idFase : ", idFase)
    let res = await Api.getActivityPhase(idFase)
      console.log("ActivityPhase > res : ", res)
    setActivityPhase(res)
  }


  const LogoffClick = () => {
    localStorage.removeItem('SessionID')
    navigate('/Login')
  }
 
  const UploadFile = async () => {
    let res = await Api.uploadFile(file)
    let res2 = await Api.upgUser({usr_id: user.id, usr_foto: file.name})
    setPopUp(false)
    window.location.reload(false);
  }


  
/// DETECTA AS TECLAS ESCAPE (ESC) E ENTER 
  document.onkeydown = function (event) {
    switch (event.code) {
      case 'Escape':
        setSubMenuCad(false);
        setSubMenuFin(false);
        setSubMenuOth(false);
        setPopUp(false);
        setModal(false);
        setStep(1);
        setActivityPhase('');
        setProcessNumber('');
        break;
      default:
        break;
    }
  }



/// USEEFECT //////////////////////////

  useEffect(() => {
    reset();
  }, [modal]);


/// CONSOLE ///////////////////////////
   
//console.log(errors)



  return (

    <C.Container>

      <div style={{ position: 'absolute' }}>
        <C.Logo src={"/logo.png"} style={{ width: '95px', borderRadius: '50%', marginTop: '15px'}}/>
      </div>

      <div style={{ width: '100%', margin: '0px'  }}>
        <G.OptionsArea style={{ position: 'absolute', right: '0', top: '0', marginTop: '18px', marginRight: '10px' }}>
          {popUp &&
            <G.PopUpArea style={{ position: 'absolute', height: '220px', justifyContent: 'center', alignItems: 'center', marginRight: '200px', marginTop: '250px', backgroundColor: '#ddd', boxShadow: 'none', border: '2px solid #f0f0f0' }}>
              <G.Image 
                style={{ height: '100px', borderRadius: '50px', border: '3px solid #f5f5f5', width: '100px' }} 
                src={file ? URL.createObjectURL(file) : `${process.env.REACT_APP_BACKEND}/download?file=${user.foto}`} 
              />
              <G.Input 
                type="file" style={{ backgroundColor: '#ddd', color: '#333', marginTop: '10px', padding: '15px 10px 15px 10px', borderBottom: '1px dotted #999', borderRadius: '0px' }} 
                onChange={e => setFile(e.target.files[0])} 
              />
              <G.OptionsArea style={{ flexDirection: 'row' }}>
                <G.Button style={{ backgroundColor: '#E95839', marginTop: '12px' }} onClick={() => setPopUp(false)}>
                  Fechar
                </G.Button>
                <G.Button style={{ backgroundColor: '#2E8B57', marginTop: '12px' }} onClick={UploadFile}>
                  Enviar
                </G.Button>
              </G.OptionsArea>
            </G.PopUpArea>
          }
          <C.UserArea onClick={() => setPopUp(popUp === true ? false : true)}>
            {user.foto !== null
              ? <G.Image 
                  src={`${process.env.REACT_APP_BACKEND}/download?file=${user.foto}`} 
                  style={{ width: '35px', height: '35px', borderRadius: '50%', border: '3px solid #777', marginRight: '3px' }}
                />
              : <G.Image 
                  src={'/person.svg'} 
                  style={{ minWidth: '40px', height: '40px', borderRadius: '22px', border: '2px solid #fff', marginRight: '3px' }} 
                />
            }
            <C.UserInfoText style={{fontWeight: 'bold', fontSize: '13px'}}>{user.name}</C.UserInfoText>
          </C.UserArea>
          <G.ButtonMenuExit>
            <ExitToAppIcon fontSize='large' onClick={LogoffClick}/>
          </G.ButtonMenuExit>
        </G.OptionsArea>

        <G.TrayMenu>
          <G.Menu style={{ width: '800px', justifyContent: 'start', backgroundColor: 'inherit', color: '#fff'  }}></G.Menu>
        </G.TrayMenu>

        <G.AreaMenu >
          
          {/* /// CRIAR NOVO PROCESSO /// */}
          {user.adm === 1 &&
            <G.MenuSpecial>
              <G.ButtonMenuSpecial onClick={() => ModalClick() + setSubMenuCad(false) + setSubMenuFin(false) + setSubMenuOth(false)}>
                <AddCircleOutlineIcon style={{ marginRight: '8px', color: '#fff' }}/>
                <G.SmallTitle style={{ color: '#fff' }}>Processo</G.SmallTitle>
              </G.ButtonMenuSpecial>
            </G.MenuSpecial>
          }

          <G.Menu style={{ borderLeft: '1px solid #e0e0e0'}}>
            {/* /// MENU PRINCIPAL /// */}
            <G.ButtonMenu onClick={() => MenuItemClick('/Dashboard') + setSubMenuCad(false) + setSubMenuFin(false) + setSubMenuOth(false)}>
              <DashboardIcon style={{ marginRight: '8px' }}/>
              <G.SmallTitle>Dashboard</G.SmallTitle>
            </G.ButtonMenu>
            <G.ButtonMenu onClick={() => MenuItemClick('/Processes') + setSubMenuCad(false) + setSubMenuFin(false) + setSubMenuOth(false)}>
              <TaskIcon style={{ marginRight: '8px' }}/>
              <G.SmallTitle>Processos</G.SmallTitle>
            </G.ButtonMenu>

            {/* /// MENU CADASTRO /// */}
            <G.ButtonMenu 
              onClick={() => setSubMenuCad(subMenuCad === false ? true : false) + setSubMenuFin(false) + setSubMenuOth(false)} 
              style={{ backgroundColor: user.adm !== 1 ? '' : subMenuCad ? '#EDEDFF' : ''}}>
              
              {/* <AppRegistrationIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
              <G.SmallTitle >Cadastros</G.SmallTitle>
              {user.adm !== 1 ? <LockIcon fontSize="small" /> : subMenuCad ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}

              {user.adm !== 1 && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', color: '#888' }}>
                  <AppRegistrationIcon sx={{ borderBottom: '1px dotted #888' }} style={{ marginRight: '8px' }}/>
                  <G.SmallTitle>Cadastros</G.SmallTitle>
                  <LockIcon fontSize="small" />
                </div>
              }
              {user.adm === 1 && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                  <AppRegistrationIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
                  <G.SmallTitle >Cadastros</G.SmallTitle>
                  {subMenuCad ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              }

              {/* /// SUBMENU CADASTRO /// */}
              {(user.adm === 1 && subMenuCad) && 
                <G.PopUpAreaMenu style={{ alignItems: 'center', padding: '12px 16px', marginTop: '448px' }}>
                  <G.ButtonMenu style={{ marginBottom: '6px' }} onClick={() => MenuItemClick('/Users')}>
                    <AccountCircleIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Usuários</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Lawyers')}>
                    <BusinessCenterIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Advogados</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Companies')}>
                    <BusinessIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Empresas</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Phases')}>
                    <AccountTreeIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Fases</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px' }} onClick={() => MenuItemClick('/Activities')}>
                    <FactCheckIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Atividades</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Employees')}>
                    <G.TextButtonSubMenu>
                      <BadgeIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Funcionários</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px' }} onClick={() => MenuItemClick('/Suppliers')}>
                    <G.TextButtonSubMenu>
                      <ConstructionIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Fornecedores</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} /> */}
                </G.PopUpAreaMenu>
              }
            </G.ButtonMenu>

            {/* /// MENU FINANCEIRO /// */}
            <G.ButtonMenu onClick={() => setSubMenuFin(subMenuFin === false ? true : false) + setSubMenuCad(false) + setSubMenuOth(false)}
                            style={{ backgroundColor:  user.adm !== 1 ? '' : subMenuFin ? '#EDEDFF' : ''}}>

              {/* <LocalAtmIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
              <G.SmallTitle >Financeiro</G.SmallTitle>
              {user.adm !== 1 ? <LockIcon fontSize="small" /> : subMenuFin ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}

              {user.adm !== 1 && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', color: '#888' }}>
                  <LocalAtmIcon sx={{ borderBottom: '1px dotted #888' }} style={{ marginRight: '8px' }}/>
                  <G.SmallTitle>Financeiro</G.SmallTitle>
                  <LockIcon fontSize="small" />
                </div>
              }
              {user.adm === 1 && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                  <LocalAtmIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
                  <G.SmallTitle >Financeiro</G.SmallTitle>
                  {subMenuFin ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              }

              {/* /// SUBMENU FINANCEIRO /// */}
              {( user.adm === 1 && subMenuFin) && 
                <G.PopUpAreaMenu style={{ alignItems: 'center', padding: '12px 16px', marginTop: '406px' }}>
                  <G.ButtonMenu style={{ marginBottom: '6px' }} onClick={() => MenuItemClick('/Receipts')}>
                    <G.TextButtonSubMenu>
                      <LoginIcon style={{ marginRight: '8px', transform: 'rotate(90deg)' }}/>
                      <G.SmallTitle>Entradas</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Payments')}>
                    <G.TextButtonSubMenu>
                      <LogoutIcon style={{ marginRight: '8px', transform: 'rotate(270deg)' }}/>
                      <G.SmallTitle>Saídas</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>

                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/ReceiptCategories')}>
                    <G.TextButtonSubMenu>
                      <LoginIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Categ. Entrada</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/PaymentCategories')}>
                    <G.TextButtonSubMenu>
                      <LogoutIcon style={{ marginRight: '8px', transform: 'rotate(180deg)' }}/>
                      <G.SmallTitle>Categ. Saída</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>

                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Banks')}>
                    <G.TextButtonSubMenu>
                      <AccountBalanceIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Bancos</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />
                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Accounts')}>
                    <G.TextButtonSubMenu>
                      <AccountBalanceWalletIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Contas</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} /> */}
                </G.PopUpAreaMenu>
              }
            </G.ButtonMenu>


            {/* /// MENU OUTROS /// */}
            <G.ButtonMenu onClick={() => setSubMenuOth(subMenuOth === false ? true : false) + setSubMenuCad(false) + setSubMenuFin(false)}
                            style={{ backgroundColor:  user.adm !== 1 ? '' : subMenuOth ? '#EDEDFF' : ''}}>

              {/* <LocalAtmIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
              <G.SmallTitle >Financeiro</G.SmallTitle>
              {user.adm !== 1 ? <LockIcon fontSize="small" /> : subMenuOth ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}

              {user.adm !== 1 && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', color: '#888' }}>
                  <ViewWeekIcon sx={{ borderBottom: '1px dotted #888' }} style={{ marginRight: '8px' }}/>
                  <G.SmallTitle>Outros</G.SmallTitle>
                  <LockIcon fontSize="small" />
                </div>
              }
              {user.adm === 1 && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                  <ViewWeekIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
                  <G.SmallTitle >Outros</G.SmallTitle>
                  {subMenuOth ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              }

              {/* /// SUBMENU OUTROS /// */}
              {( user.adm === 1 && subMenuOth) && 
                <G.PopUpAreaMenu style={{ alignItems: 'center', padding: '12px 16px', marginTop: '132px' }}>
                  <G.ButtonMenu style={{ marginTop: '0px' }} onClick={() => MenuItemClick('/Integration')}>
                    <G.TextButtonSubMenu>
                      <IntegrationInstructionsIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Integração</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} /> */}
                </G.PopUpAreaMenu>
              }
            </G.ButtonMenu>

          </G.Menu>

        </G.AreaMenu>
      </div>


      {modal &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ padding: '15px', backgroundColor: '#e9e9e9'}}>

            {step === 1 &&
              <M.ModalBody 
                style={{ width: '700px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <G.BoxBody style={{color: '#fff', flexDirection: 'column'}}>
                  <C.Info >Criar um novo processo do tipo:</C.Info>
                  <C.Button style={{padding: '12px'}} onClick={() => StepClick(2, 1)}>
                    <G.DistacInfoText >
                      <TaskIcon fontSize="large" style={{ color: '#F15068', marginRight: '-2px' }}/>
                      {'｜'}{'Perícia'}
                    </G.DistacInfoText>
                  </C.Button>
                  <C.Button  style={{padding: '12px'}} onClick={() => StepClick(2, 2)}>
                    <G.DistacInfoText>
                      <TaskIcon fontSize="large" style={{ color: '#51B476', marginRight: '-2px' }}/>
                      {'｜'}{'Reclamante'}
                    </G.DistacInfoText>
                  </C.Button>
                  <C.Button  style={{padding: '12px'}} onClick={() => StepClick(2, 3)}>
                    <G.DistacInfoText>
                      <TaskIcon fontSize="large" style={{ color: '#84b6f4', marginRight: '-2px' }}/>
                      {'｜'}{'Reclamada'}
                    </G.DistacInfoText>
                  </C.Button>
                </G.BoxBody>
              </M.ModalBody>
            }

            {step === 2 &&
              <>
              <M.ModalHeader 
                style={{ 
                  height:'28px', 
                  justifyContent: 'space-evenly', 
                  borderBottom: '1px solid #ccc'
                }}
              >
                <TaskIcon fontSize="large" style={{ color: type === 1 ? '#F15068' : type === 2 ? '#51B476' : type === 3 ? '#84b6f4' : '#333' }}/>
                <G.InfoText style={{ width: '96%', height: '28px', alignItems: 'flex-end',fontSize: '18px', color: '#222' }}>
                {'Novo Processo'}{' - '}{type === 1 ? 'Perícia' : type === 2 ? 'Reclamante' : type === 3 ? 'Reclamada' : 'Outro'}
                </G.InfoText>
              </M.ModalHeader>
            
                <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center' }}>
                  <G.BoxBody style={{ margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                    <G.FormInput value={type} type="hidden" {...register("proc_tipo")}/>

                    <G.FormInputArea  style={{ width: '40%' }}> 
                      <G.FormInputLabel 
                        type='text' 
                        name='Identificacao' 
                        style={{ borderLeft: '3px solid purple' }}>
                        Identificação
                      </G.FormInputLabel>
                      <G.FormInput {...register("proc_identificacao", { required: true })}/>
                    </G.FormInputArea>
                    
                    <G.FormInputArea style={{ width: '32.5%' }}>
                      <G.FormInputLabel 
                        type='text' 
                        name='Processo' 
                        style={{ borderLeft: '3px solid orange' }}>
                        Processo
                      </G.FormInputLabel>
                      <G.FormInput  {...register("proc_codigo", { required: true })}/>
                    </G.FormInputArea>

                    <G.FormInputArea style={{ width: '22%' }}>
                      <G.FormInputLabel>
                        Entrada
                      </G.FormInputLabel>
                      <G.FormInput 
                        type="date" 
                        name='Entrada' 
                       {...register("proc_data_entrada", { required: true })}/>
                    </G.FormInputArea>

                    <G.FormInputArea style={{ width: '48%' }}>
                      <G.FormInputLabel>
                        Junta
                      </G.FormInputLabel>
                      <G.FormInput 
                        type='text' 
                        name='Junta' 
                        {...register("proc_vara_junta", { required: true })}/>                
                    </G.FormInputArea>

                    <G.FormInputArea style={{ width: '48%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid navy' }}>
                        Núm. Processo
                      </G.FormInputLabel>
                      <G.FormInput 
                        type="text" 
                        name='NumProcesso'
                        //maxLength="25" 
                        placeholder='Código Processo TRT' 
                        value={
                          processNumber === null 
                          ? '' 
                          : mask(processNumber, ['9999999-99.9999.9.99.9999'])
                        }
                        onChange={onChange}
                      />
                    </G.FormInputArea>

                    <G.FormInputArea style={{ width: '48%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                        Reclamante
                      </G.FormInputLabel>
                      <G.FormInput 
                        type="text" 
                        name='Reclamante'
                        {...register("proc_reclamante", { required: true })}/>                      
                    </G.FormInputArea>

                    {/* PROCESSO RECLAMANTE || PROCESSO RECLAMADA */}
                    {(type === 2 || type === 3) &&
                      <G.FormInputArea style={{ width: '48%' }}>
                        <G.FormInputLabel>Advogado</G.FormInputLabel>
                        <G.FormSelect 
                          name='Advogado' 
                          {...register("proc_advogado_id", { required: true })} onMouseOver={() => GetSelect(2)}>
                          <option value='' default disabled selected hidden>Selecione um Advogado</option>
                          {lawyers.map(a => (
                            <option key={a.adv_id} value={a.adv_id}>{a.adv_nome}</option>
                          ))}
                        </G.FormSelect>                      
                      </G.FormInputArea>
                    }

                    {/* PROCESSO PERÍCIA */}
                    {type === 1 &&
                      <G.FormInputArea style={{width: '48%',}}>
                        <G.FormInputLabel 
                          type="text" 
                          name='Perito'
                          style={{ borderLeft: '3px solid #E95839' }}>
                          Perito Responsável
                        </G.FormInputLabel>
                        <G.FormInput{...register("proc_perito_nome", { required: true })} />
                      </G.FormInputArea>
                    }

                    <G.FormInputArea style={{width: '48%',}}>
                      <G.FormInputLabel 
                        type="text" 
                        name='Reclamada'
                        style={{ borderLeft: '3px solid #1E90FF' }}>
                        Reclamada
                      </G.FormInputLabel>
                      <G.FormInput  {...register("proc_reclamada", { required: true })}/>                      
                    </G.FormInputArea>
        
                    {/* <G.FormInputArea style={{width: '20%'}}>
                      <G.FormInputLabel>Prazo</G.FormInputLabel><G.FormInput type="date" {...register("prazo")}/>                      
                    </G.FormInputArea> */}

                    <G.FormInputArea style={{width: '48%'}}>
                      <G.FormInputLabel>Calculista</G.FormInputLabel>
                      <G.FormSelect 
                        name='Calculista'
                        {...register("proc_calculista_nome", { required: true })} onMouseOver={() => GetSelect(3)}>
                        <option value='' default disabled selected hidden>Selecione um Calculista</option>
                        {users.map(c => (
                          <option key={c.usr_id} value={c.usr_nome}>{c.usr_nome}</option>
                        ))}
                      </G.FormSelect>
                    </G.FormInputArea>

                    {/* PROCESSO RECLAMADA */}
                    {type === 3 &&
                      <G.FormInputArea  style={{width: '48%'}}>
                        <G.FormInputLabel 
                          style={{ borderLeft: '3px solid gray' }}
                        >
                            Empresa
                          </G.FormInputLabel>
                        <G.FormSelect 
                          name='Empresa'
                          {...register("proc_empresa_id", { required: true })} onMouseOver={() => GetSelect(1)}>
                          {/* <option value='' default disabled selected hidden>Selecione uma Empresa</option> */}
                          <option value='' default disabled selected hidden>Selecione uma Empresa</option>
                          {companies.map(e => (
                            <option key={e.emprs_id} value={e.emprs_id}>{e.emprs_nome}</option>
                          ))}
                        </G.FormSelect>
                      </G.FormInputArea>      
                    }   
                  </G.BoxBody>
                  <G.BoxFooter>
                  {(Object.keys(errors).length !== 0 ) &&   
                  <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '7px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                    <WarningRoundedIcon  style={{ color: '#333', marginRight: '5px'  }}/>
                    {'PARA SALVAR OU ADICIONAR UMA NOVA FASE TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS.'}
                  </div>
                  }
                  </G.BoxFooter>

                </M.ModalBody>
              </>
            }


            {/* MODAL - CADASTRO NOVO PROCESSO */}
            {step === 3 &&
              <>
                <M.ModalBody style={{ width: '700px', height: '400px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                  <G.BoxBody style={{ flexDirection: 'column', height: '400px', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>

                    <G.SmallTitle style={{ padding: '10px',  width: '100%', color: '#000' }}>
                        Adicionar Fase
                    </G.SmallTitle>

                    <G.BoxBody style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'start', color: '#fff' }}>

                    <G.FormInputArea style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                      <div style={{ display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'space-between', margin: '4px 2px 8px 8px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                          <G.FormInputLabel style={{ borderLeft: '3px solid green', borderRadius: '4px 0px 0px 4px' }}>
                            Entrada
                          </G.FormInputLabel>
                          <G.FormInput type="date" onChange={(e) => setFaseEntrada(e.target.value)}/> 
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                          <G.FormInputLabel style={{ borderLeft: '3px solid orange', borderRadius: '4px 0px 0px 4px' }}>
                            Prazo
                          </G.FormInputLabel>
                          <G.FormInput type="date" onChange={(e) => setFasePrazo(e.target.value)}/>
                        </div>
                      </div>
                      <div style={{ width: '96%', justifyContent: 'space-around', margin: '4px' }}>
                        <G.FormSelect required onChange={e => ActivityPhase(e.target.value)} onMouseOver={() => GetSelect(4)} style={{ borderRadius: '4px' }}>
                          <option value='' default disabled selected hidden>Selecione a Fase a adicionar</option>
                          {phases.map(f => (
                            <option key={f.fase_id} value={f.fase_id}>{f.fase_descricao}</option>
                          ))}
                        </G.FormSelect>
                      </div>

                    </G.FormInputArea>

                    </G.BoxBody>

                    <G.InfoArea style={{ width: '520px', padding: '10px', marginBottom: '10px', borderRadius: '0px', borderBottom: '1px solid #ccc', fontSize: '12px', color: '#000' }}>
                      Atividades
                    </G.InfoArea>
                    {(activityPhase === '' || activityPhase.length === 0) &&
                      <G.InfoArea style={{ width: '50%', justifyContent: 'space-around', padding: '6px', borderRadius: '4px', backgroundColor: '#FFEEAD', color: 'black' }}>
                        <WarningRoundedIcon  style={{ color: '#ffb400'  }}/>
                        <G.InfoText>
                          Fase com nenhuma atividade cadastrada
                        </G.InfoText>
                      </G.InfoArea>
                    }
                    <>
                      <div style={{ display: 'flex', width: '100%', height: '300px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '16px' }}> 
                        {activityPhase !== '' &&
                          <G.InfoArea style={{ width: '520px', flexDirection: 'row', justifyContent: 'space-aroud', flexWrap: 'wrap' }}> 
                          {activityPhase.map((item, key)=>(
                            <G.FormInputArea key={key} style={{ flexDirection: 'column', alignItems: 'start', marginRight: '20px' }}>
                              <G.FormInputLabel style={{ 
                                width: '100%', 
                                justifyContent: 'center', 
                                borderRadius: '4px', 
                                borderLeft: '0px solid black', 
                                backgroundColor: '#ccc' 
                                }}
                              >
                                {item.ctrl_atvd_nome}
                              </G.FormInputLabel>
                            </G.FormInputArea>
                          ))}  
                          </G.InfoArea>
                        }
                      </div>
                    </>
                    
                  </G.BoxBody>

                </M.ModalBody>
              </>
            }
               
            <M.ModalFooter style={{ flexDirection: 'row', height: '20px', paddingTop: '10px' }}>
              <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => CloseModalClick()}>Fechar</G.Button>
                {step === 2 &&
                  <G.Button style={{ backgroundColor: '#1E90FF' }} onClick={() => handleSubmit(onSubmitStep)()}>Adicionar Fase</G.Button>
                }
                {/* {(step === 3 && activityPhase.length !== 0) &&
                  <G.Button style={{ backgroundColor: '#2E8B57' }} onClick={() => handleSubmit(onSubmit)()}>Salvar</G.Button>
                } */}
                {(step === 3) &&
                  <G.Button 
                    style={{ backgroundColor: (activityPhase.length === 0 || faseEntrada === '' || fasePrazo === '') ? '#999' : '#2E8B57' }} 
                    onClick={() => (activityPhase.length === 0 || faseEntrada === '' || fasePrazo === '') ? null : handleSubmit(onSubmit)()}
                  >
                    Salvar
                  </G.Button>
                }
            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }

    </C.Container>
    
  );
}
