import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { SHA256 } from "crypto-js";


import * as C from './styled'
import Api from '../../services/api'
import {Alert} from '../../components/Alert'
import {ModalAlert} from '../../components/ModalAlert'



export const Renew = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [userField, setUserField] = useState(location.state.login);
    const [tokenField, setTokenField] = useState('');
    const [token, setToken] = useState(false);
    const [passwordField, setPasswordField] = useState('');
    const [newpasswordField, setNewpasswordField] = useState('');

    const [userMail, setUserMail] = useState('');


    const [alert, setAlert] = useState('')
    const [alertColor, setAlertColor] = useState('')

    const [loading, setloading] = useState(false)

    const [modalAlert, setModalAlert] = useState('')
    const [modalAlertColor, setModalAlertColor] = useState('')






    const EnterClick = async () => {
            if( passwordField && newpasswordField) {
                setloading(true)
                  if (passwordField.toLowerCase() != passwordField
                  && /\d/.test(passwordField)
                  && /[~`@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(passwordField)
                  && passwordField.length >= 7
                ){
                      if( passwordField === newpasswordField) {
                        let encryption = SHA256(passwordField).toString()
                        let res2 = await Api.renew(userField, encryption)
                        if (res2 === 1  ) {
                            navigate('/Login')
                        } else {
                            setAlertColor('#E95839')
                            setAlert('Ocorreu um erro, por gentileza contate nosso time de suporte')
                            setloading(false)}

                      } else {
                      setAlertColor('#E95839')
                      setAlert('As senhas não estão iguais')
                      setloading(false)}

                  } else {
                  setAlertColor('#E95839')
                  setAlert('Senha não atende os requisitos minimos')
                  setloading(false)}
            } else {
                setAlertColor('#E95839')
                setAlert("Preencha todos os campos !")
                setloading(false)
            }


    }

    const RequiClick = () => {

            setModalAlert(`A Senha deve conter no minimo 7 carateres (letras e numeros
               1 letra maiuscula, 1 numero e um caractre especial (@!#$%&).`)
            setModalAlertColor('#FCC703')
        }




    const CloseAlertClick = () => {
        setAlert('')
    }

    const ModalAlertClick = () => {
        setModalAlert('')
    }

    const GetTokenClick = async () => {
        setloading(true)
        let res = await Api.getMail(userField)
        console.log('Renew > GetTokenClick :', res)

        if(res[0]){
            let destMail = res[0].usr_email
            setUserMail(destMail)
            let makeToken = Math.floor(Math.random() * (9999 - 1111 + 1)) +1111
            let destToken = makeToken.toString()
            let htmlTest = `<div  style="background-color: #f9f9f9;  height: 700px; width: 100%; display: flex; align-items: center; flex:1; justify-content: center;">
                            <div style="padding: 50px; background-color: #333; color: #fff; width: 400px; height: 500px;">
                            <div><img style="height: 130px; width: 130px; margin-bottom: 30px;" src="https://static.wixstatic.com/media/8e8cb2_bce3336228574a6c8b715c82677b56a3~mv2.png" /></div>
                            <p><strong>Olá !</strong></p>
                            <p>&nbsp;</p>
                            <p>Segue o token solicitado para a troca da senha:</p>
                            <p>&nbsp;</p>                            
                            <p><span style="font-size: 26px;"><strong><span style="color: #f39c12;">${destToken}</span></strong></span></p>
                            <p>&nbsp;</p>
                            <p>Esse número deve ser digitado no campo token na tela de solicitação no App GCP.</p>
                            <p><br /> <strong>Abraços da equipe Joy.</strong></p>
                            </div>
                            `
            setToken(destToken)
            let res3 = await Api.sendMail(destMail, htmlTest)
            setloading(false)
        }else {
            setAlertColor('#E95839')
            setAlert("Usuário não encontrado !")
            setloading(false)
        }


    }


    return (
        <C.Container >
            <C.InputArea >
            {token !== tokenField &&
            <h4>Insira o usuário cadastrado para criar a nova senha</h4>
            }
            <C.Input placeholder={'Usuário'}
                     autoComplete="user"
                     disabled={token ? true : false}
                     value={userField}
                     onChange={e=>setUserField(e.target.value)}
                     onFocus={CloseAlertClick}
                     style={{ backgroundColor: token ? '#777' : '#fff' }}
                     />
            {token !== tokenField &&
            <C.Button onClick={GetTokenClick}>
                {loading  &&
                <C.Logo src={"/button_loading.svg"} />
                }
                {token ? 'Gerar outro Token' : 'Gerar token'}</C.Button>
            }
            </C.InputArea>
            {token &&
            <C.InputArea>
            {token !== tokenField &&
            <h4>Verifique o token enviado ao e-mail {userMail} !</h4>
            }
             {token !== tokenField &&
            <C.Input placeholder={'Insira o Token recebido'}
                     value={tokenField}
                     onChange={e=>setTokenField(e.target.value)}
                     onFocus={CloseAlertClick}
                     maxLength='4'
                     style={{ textAlign: 'center', backgroundColor: tokenField === '' ? '#fff' : token === tokenField ? '#BDF5D6' : '#FCAC9B' }}
                     />
            }
            {token === tokenField &&
            <C.Input placeholder={'Nova Senha'}
                     autoComplete="newpassword"
                     type="password"
                     value={passwordField}
                     onChange={e=>setPasswordField(e.target.value)}
                     onFocus={CloseAlertClick}
                     />
            }
            {token === tokenField &&
            <C.Input placeholder={'Confirme sua Nova Senha'}
                     value={newpasswordField} onChange={e=>setNewpasswordField(e.target.value)}
                     type="password"
                     autoComplete="current-password"
                     onFocus={CloseAlertClick}
                     />
            }
            {token === tokenField &&
            <C.Button onClick={token === tokenField ? EnterClick : null}
            style={{ backgroundColor:'#ffb400' }}>
                 {loading &&
                <C.Logo src={"/button_loading.svg"} />
                }
                Enviar</C.Button> }
            {token === tokenField &&
            <C.ButtonClean style={{borderBottom: '0.1px solid #aaa'}}onClick={RequiClick}>
              Verificar os requisitos minimos para a nova senha!
            </C.ButtonClean>
            }
            </C.InputArea>
            }
            <C.Logo style={{height:'30px', marginTop:'30px'}}src={"/logoFooter.png"}/>


            <Alert  textAlert={alert}
                    setAlertClick={CloseAlertClick}
                    colorAlert={alertColor} />

            {modalAlert !== '' &&
            <ModalAlert modalAlert={modalAlert}
                        setModalAlertClick={ModalAlertClick}
                        colorModalAlert={modalAlertColor}/>
            }
        </C.Container>
    );
}
