import styled from 'styled-components';



export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #333;
  color: #FFF;
`;

export const Logo = styled.img`
  width: auto;
  height: 170px;
`;

export const InputArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
`;

export const Input = styled.input`
  width: 330px;
  padding: 17px 20px;
  border-radius: 12px;
  border: none;
  margin-top: 12px;
  background-color: #EEE;
  font-size: 16px;
  color: #000;
  outline: 0;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 370px;
  height: 60px;
  margin-top: 16px;
  border-radius: 16px;
  background-color: var(--primary-color);
  border: none;
  font-size: 14px;
  color: #FFF;
  cursor: pointer;
  &:hover {
    background-color: var(--edit-color)
    ;
  }
`;

export const ButtonClean = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 330px;
  height: 40px;
  border: none;
  margin-top: 16px;
  background-color: rgba(0, 0, 0, 0.01);
  font-size: 14px;
  color: #FFF;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }

`;

