import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from 'moment'

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const Accounts = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState()
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)

  const [bank, setBank] = useState([])
  const [accountType, setAccountType] = useState([])
  const [selectedAccountType, SetSelectedAccountType] = useState('')

  const [cntTipoConta, setCntTipoConta] = useState('')
  const [isValid, setIsValid] = useState('')
  const [managerField, setManagerField] = useState(false)



  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')


  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.cnt_descricao.toLowerCase().search(value) !== -1 
            || data.bco_nome_banco.toLowerCase().search(value) !== -1 
            || data.cnt_agencia.toLowerCase().search(value) !== -1 
            || data.cnt_num_conta.toLowerCase().search(value) !== -1 
            || data.tp_cnt_descricao.toLowerCase().search(value) !== -1 
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
  


  /// FUNCTIONS ////////////////////////////////////////////////////////////////

  const AddClick = () => {
    setModalType(1)
    setModal(true)
  }  

  const EditClick = (key) => {
    setSelectedKey([key])
    setModalType(2)
    setModal(true)
  }

  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }

  const ActClick = (key) => {
    setSelectedKey([key])
    setModalType(4)
    setModal(true)
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setBank('')
    setAccountType('')
    setAlertError('')
    GetAccount()
    setModal(false)
    setModalType('')
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setBank('')
    setAccountType('')
    setAlertError('')
    setModal(false)
    setModalType('')
    reset()
    setLoading(false)
  }


  const GetAccount = async () => {
    setLoading(true)
    let res = await Api.getAccount();
    setList(res)
    setFilteredList(res)
    setLoading(false)
  }


  const DelAccount = async (id) => {
    let res = await Api.delAccount(id);
    GetAccount()
  }


  // const ActAccount = async (id, estado) => {
  //   let res = await Api.upgAccount({cnt_id: id, cnt_estado: estado})
  //   GetAccount()
  // }


  const ActAccount = async (id, estado) => {
    const data = ({
      cnt_id: id, 
      cnt_estado: estado,
      cnt_usuario_atualizacao: user.id
    })
    let res = await Api.upgAccount(data)
    //console.log("ActAccount > res : ", res)
    GetAccount()
  }


  // const GetSelect =  async (value) => {
  //   if (value === 1) {
  //     let res = await Api.getBank()
  //     setBank(res)
  //   } else if (value === 2) {
  //     let res = await Api.getAccountType()
  //     setAccountType(res)
  //   } 
  // }


  const GetSelect =  async (value) => {
    switch (value) {
      case 1:
        let res1 = await Api.getBank()
        setBank(res1)
      break;

      case 2:
        let res2 = await Api.getAccountType()
        setAccountType(res2)
      break;

      default:
      break;
    }
  }


  // const onSubmit = async (data) => {
  //   if(modalType === 1) {
  //     let res = await Api.addAccount(data)
  //   } else if (modalType === 2) {
  //     let res = await Api.upgAccount(data)
  //   } else{}
  //   CloseModalClick(selectedAccountType)
  // };


  const onSubmit = async (data) => {
    //console.log("onSubmit > onSubmit: ", data)
    switch (modalType) {
      case 1:
        const userAdd = ({
          cnt_usuario_criacao: user.id,
          cnt_usuario_atualizacao: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await Api.addAccount(add)
        console.log("addAccount > res1 : ", res1)
      break;

      case 2:
        const userUpg = ({
          cnt_usuario_atualizacao: user.id
        })
        const upg = ({...data, ...userUpg})
        let res2 = await Api.upgAccount(upg)
        //console.log("upgAccount > res2 : ", res2)
      break;

      default:
      break;
    }
    CloseModalClick()
  };


  
/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetAccount();
  }, [state]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////





  return (
    <G.Container>

      <G.Header>

        <G.HeaderTitleArea style={{ width: '240px' }}>
          <AccountBalanceWalletIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
          <G.MainTitle>Contas</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>

        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>

      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>
              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'230px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('cnt_descricao')}><G.DistacInfoText>Nome da Conta</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'cnt_descricao') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'210px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('bco_nome_banco')}><G.DistacInfoText>Banco</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'bco_nome_banco') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('cnt_agencia')}><G.DistacInfoText>Agência</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'cnt_agencia') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'110px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('cnt_num_conta')}><G.DistacInfoText>Conta</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'cnt_num_conta') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('tp_cnt_descricao')}><G.DistacInfoText>Tipo</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'tp_cnt_descricao') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                {filteredList.length === 0 && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir. ***
                  </G.Text>
                }
                {filteredList.length !== 0 &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border:  item.cnt_estado === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}>
                          <AccountBalanceWalletIcon style={{ fontSize: '22px', color: '#000' }} /></C.ContactTypeIconArea></G.Text>
                        </G.FieldArea>
                        <G.FieldArea size={'230px'}><G.Text>{item.cnt_descricao}</G.Text></G.FieldArea>
                        <G.FieldArea size={'210px'}><G.Text>{item.bco_nome_banco}</G.Text></G.FieldArea>
                        <G.FieldArea size={'100px'}><G.Text>{item.cnt_agencia}</G.Text></G.FieldArea>
                        <G.FieldArea size={'110px'}><G.Text>{item.cnt_num_conta}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}><G.Text>{item.tp_cnt_descricao}</G.Text></G.FieldArea>
                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                          {/* <G.IconButton onClick={() => ActAccounts(item.cnt_id, item.cnt_estado === 0 ? 1 : 0)} style={{ backgroundColor: item.cnt_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.cnt_estado === 0 ? <CheckIcon fontSize="small"/> : <BlockIcon fontSize="small"/>}</G.IconButton> */}
                          <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.cnt_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.cnt_estado === 0 ? <CheckIcon fontSize="small"/> : item.cnt_estado === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}</G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>
            </G.Box>
          </>
        }

      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentSmall>

            <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].cnt_estado === 0 ? '#BDF5D6' : '#ccc'), justifyContent: 'space-between', bordeRadius: '15px' }}>
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Conta' : modalType === 2 ? 'Editar Conta' : modalType === 3 ? 'Excluir Conta' : (filteredList[selectedKey].cnt_estado === 0 ? 'Ativar Conta' : 'Desativar Conta')}</C.ModalTitle>
                <G.InfoArea>
                  <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                </G.InfoArea>
            </M.ModalHeader>

            <M.ModalBody style={{ flexDirection: 'column'}}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cnt_id} {...register("cnt_id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBody style={{ flexDirection: 'column'}}>
                  <G.FormInputArea>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #AAA' }}>
                      Nome da Conta
                    </G.FormInputLabel>

                      <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cnt_descricao} {...register("cnt_descricao", {required:  true})} />
                      {errors.cnt_descricao && <span style={{ fontSize: '12px', color: 'red', }}>(*) Preencha o Nome da Conta.</span>}

                  </G.FormInputArea>
                  <G.FormInputArea>
                    <G.FormInputLabel style={{ borderLeft: modalType === 1 ? '3px solid #2E8B57' : modalType === 2 ? '3px solid #1E90FF' : '3px solid #AAA' }}>
                      Tipo
                    </G.FormInputLabel>
                    <G.FormSelect {...register("cnt_tipo_conta", { setValueAs: v => v === '' ? null : v, required: true })}  onMouseDown={() => GetSelect(2)}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].cnt_tipo_conta}>{modalType === 1 ? 'Selecione o Tipo da Conta' : filteredList[selectedKey].tp_cnt_descricao}</option>
                      {accountType.map(i => (
                        <option key={i.tp_cnt_id} value={i.tp_cnt_id}>{i.tp_cnt_descricao}</option>
                      ))}
                    </G.FormSelect>
                    {errors.cnt_tipo_conta && <span style={{ fontSize: '12px', color: 'red', }}>(*) Selecione o Tipo da Conta.</span>}
                  </G.FormInputArea>
                  {
                    <>
                      <G.FormInputArea>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                          Banco
                        </G.FormInputLabel>
                        <G.FormSelect {...register("cnt_bco_id", { setValueAs: v => v === '' ? null : v, required: cntTipoConta !== 1 ? true : false })} onMouseDown={() => GetSelect(1)}>
                          <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].cnt_bco_id}>{modalType === 1 ? 'Selecione o Banco' : filteredList[selectedKey].bco_nome_banco}</option>
                          {bank.map(i => (
                            <option key={i.bco_id} value={i.bco_id}>{i.bco_nome_banco}</option>
                          ))}
                        </G.FormSelect>
                        {errors.cnt_bco_id && <span style={{ fontSize: '12px', color: 'red', }}>(*) Selecione o Banco.</span>}
                      </G.FormInputArea>
                      <G.FormInputArea>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                          Agência
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cnt_agencia} {...register("cnt_agencia", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>
                      <G.FormInputArea>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                          Conta
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cnt_num_conta} {...register("cnt_num_conta", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>
                    </>
                  }
                  <G.FormInputArea>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #AAA' }}>
                      Saldo Inicial
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].cnt_saldo_inicial_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("cnt_saldo_inicial_valor" , {
                                setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")), required:  true})} />
                  </G.FormInputArea>
                  
                  <G.FormInputArea>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #AAA' }}>
                      Data Inicial
                    </G.FormInputLabel>
                    <G.FormInput type="date" defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].cnt_saldo_inicial_data).format('YYYY-MM-DD')} {...register("cnt_saldo_inicial_data", {required:  true})} />
                  </G.FormInputArea>

                </G.BoxBody>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja excluir a Conta:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].cnt_descricao}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].cnt_estado === 0 ? 'ativar' : 'desativar'} a Conta:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].cnt_descricao}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                // <G.Button onClick={SendAccounts} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelAccount(filteredList[selectedKey].cnt_id) + CloseModalClick()}>Sim</G.Button>
                </>
              }
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActAccount(filteredList[selectedKey].cnt_id, filteredList[selectedKey].cnt_estado === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }
            </M.ModalFooter>

          </M.ModalContentSmall>
        </M.ModalContainer>
      }
    </G.Container>
  );
}
