import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { groupBy } from "core-js/actual/array/group-by";

import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import { GrServer } from "react-icons/gr";

import AllInboxIcon from '@mui/icons-material/AllInbox';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BackupIcon from '@mui/icons-material/Backup';
import BlockIcon from '@mui/icons-material/Block';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import CloudIcon from '@mui/icons-material/Cloud';
import CodeIcon from '@mui/icons-material/Code';
import ComputerIcon from '@mui/icons-material/Computer';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DescriptionIcon from '@mui/icons-material/Description';
import DevicesIcon from '@mui/icons-material/Devices';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LanguageIcon from '@mui/icons-material/Language';
import MailIcon from '@mui/icons-material/Mail';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SellIcon from '@mui/icons-material/Sell';
import StorageIcon from '@mui/icons-material/Storage';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TodayIcon from '@mui/icons-material/Today';


export const Services = () => {
  const navigate = useNavigate();

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState('')
  const [contract, setContract] = useState({})
  const [terms, setTerms] = useState([])
  const [termDetails, setTermDetails] = useState([])
  const [termServices, setTermServices] = useState([])
  const [selectedService, setSelectedService] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [contractSum, setContractSum] = useState('')
  const [contractDiscountSum, setContractDiscountSum] = useState('')
  const [serviceDetails, setServiceDetails] = useState(false)
  const [serviceDetailsSelected, setServiceDetailsSelected] = useState([])
  const [showModal, setShowModal] = useState('')
  const [showFilteredServices, setShowFilteredServices] = useState([])
  const [serviceType, setServiceType] = useState('')
  const [serviceActive, setServiceActive] = useState({})
  const [upDownSelectedService, setUpDownSelectedService] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [ticketNumber, setTicketNumber]  = useState(null)
  const [isVM, setIsVM] = useState(false)


  const CheckIfItVM = () => {
    if (termServices.find(x => x.service_name === 'VCPU 2.6 GHZ (Mensal) | IaaS')) {
      setIsVM(true)
    }
  }


  const ClickDownload = (file) => {
    window.open(`http://suporte.joyit.com.br:42300/download?file=${file}`)
  }

  const ServiceClick = (service) => {
    setContract({})
    setTerms([])
    setTermDetails([])
    setTermServices([])
    setSelectedService(service)
  }

  const ShowContract = () => {
    document.getElementById('contract')?.scrollIntoView({ behavior: 'smooth' })
  }

  const ModalClick = (value, typeService, gbQuota, sbArray) => {
    getServices(value, typeService, gbQuota)
    setServiceType(typeService)
    setServiceActive(sbArray)
    setShowModal(value)
  }

  const UpDownClick = (value) => {
    setUpDownSelectedService(value)
  }

  const getContracts = async () => {
    setLoading(true);
    let res = await Api.getContracts(user.id);
    let resService = res.find(x => x.contract_type === selectedService)
    if (resService) {
      setContract(resService)
      let resTerm = await Api.getTerms(resService.contract_number);
      setTerms(resTerm.term.reverse())
      let resTermActive = resTerm.term.find(x => x.term_status === 'Vigente')
      if (resTermActive) {
        let resTermDetails = await Api.getTermDetails(resTermActive.term_id);
        setTermDetails(resTermDetails)
        const groups = resTermDetails.services.groupBy((service) => {
          return service.service_device
        })
        setTermServices(groups)
        setContractSum(resTermDetails.services.reduce((a, b) => a + parseFloat(b.service_subtotal), 0))
        setContractDiscountSum(resTermDetails.services.reduce((a, b) => a + parseFloat(b.service_total), 0))
        setLoading(false);
      }
    } else {
      setContract('')
    }
    setLoading(false);
  }

  const getServices = async (value, typeService, gbQuota) => {
    let res = await Api.getServices()
    let res2 = res.filter((services) => {
      if (value === 'Downgrade') {
        return services.service_type === typeService && services.quota_gb < gbQuota
      }
      else {
        return services.service_type === typeService && services.quota_gb > gbQuota
      }
    })
    let filteredServices = res2.sort(function (a, b) {
      return +(a.quota_gb > b.quota_gb) || +(a.quota_gb === b.quota_gb) - 1;
    });
    setShowFilteredServices(filteredServices)
  }



  const TicketClick = async () => {
    setLoadingModal(true)
    const fullSubject = showModal.toUpperCase() + " | " + upDownSelectedService
    const ticketBody = "Foi solicitado " + (showModal === 'Cancelar' ? 'CANCELAMENTO do' : showModal === 'Solicitar Alteração' ? 'ALTERAÇÃO do' : showModal.toUpperCase() + ' para o') + ' serviço ' + upDownSelectedService.toUpperCase() + "."
    let send = await Api.openTicket(fullSubject, ticketBody, 'Contrato', 'NO-SLA', user.user)
    if (send.ArticleID) {
      setTicketNumber(send.TicketNumber)
      setLoadingModal(false)
    } else {

        setTicketNumber('TicketCreateError')
        setLoadingModal(false)
      }
  }



  // Detecta a tecla Escape (Esc) e fecha o Modal.
  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      setConfirm(false)
      setUpDownSelectedService(false)
      setShowModal('')
    }
  }



  useEffect(() => {
    getContracts();
  }, [selectedService]);






  return (
    <G.Container>
      <G.Header>
        <G.HeaderTitleArea>
          <SellIcon fontSize='large' style={{ marginRight: "7px" }} />
          <G.MainTitle>| Serviços</G.MainTitle>
        </G.HeaderTitleArea>
        {/* SERVIÇOS - CABEÇALHO - MENU */}
        <G.BoxHeaderOptionsArea>
          {selectedService !== '' &&
            <G.StateArea style={{ padding: '4px 16px' }}>
              <G.Image
                src={'/cloud.svg'}
                onClick={() => ServiceClick('Cloud')}
                style={{ height: selectedService === 'Cloud' ? '36px' : '28px', color: selectedService === 'Cloud' ? '#000' : '#777', cursor: 'pointer' }}
              />
              <G.Image
                src={'/server_rack.svg'}
                onClick={() => ServiceClick('Suporte Servidor')}
                style={{ height: selectedService === 'Suporte Servidor' ? '36px' : '28px', color: selectedService === 'Suporte Servidor' ? '#000' : '#777', cursor: 'pointer' }}
              />
              <G.Image
                src={'/code.svg'}
                onClick={() => ServiceClick('Soluções')}
                style={{ height: selectedService === 'Soluções' ? '36px' : '28px', color: selectedService === 'Soluções' ? '#000' : '#777', cursor: 'pointer' }}
              />
            </G.StateArea>
          }
        </G.BoxHeaderOptionsArea>
      </G.Header>

      {/* SERVIÇOS - MENU PRINCIPAL */}
      {selectedService === '' &&
        <G.Body style={{ flexDirection: 'row', height: '100%', alignItems: 'center' }}>
          <G.BigButton onClick={() => { ServiceClick('Cloud') }}>
            <G.Image src={'/cloud.svg'} style={{ height: '100px', marginBottom: '15px' }} />
            <G.DistacInfoText>CLOUD</G.DistacInfoText>
          </G.BigButton>
          <G.BigButton onClick={() => { ServiceClick('Suporte Servidor') }}>
            <G.Image src={'/server_rack.svg'} style={{ height: '100px', marginBottom: '15px' }} />
            <G.DistacInfoText>SUPORTE SERVIDOR</G.DistacInfoText>
          </G.BigButton>
          <G.BigButton onClick={() => { ServiceClick('Soluções') }}>
            <G.Image src={'/code.svg'} style={{ height: '100px', marginBottom: '15px' }} />
            <G.DistacInfoText>SOLUÇÕES</G.DistacInfoText>
          </G.BigButton>
        </G.Body>
      }
      {loading && selectedService !== '' &&
        <G.Loading src={'/loading.svg'} style={{ height: '170px' }} />
      }

      {/* SERVIÇO NÃO CONTRATADO - CONTRATAR */}
      {contract === '' && selectedService !== '' &&
        <G.Body style={{ height: '100%' }}>
          <G.Card>
            <G.CardHeader style={{ height: '40px', justifyContent: 'center' }}>
              Sua empresa não possui contrato do tipo
            </G.CardHeader>
            <G.CardBody style={{ justifyContent: 'center', alignItems: 'center',marginTop: '35px' }}>
              <G.DistacInfoText style={{ justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>
                {selectedService}
              </G.DistacInfoText>
            </G.CardBody>
            <G.CardFooter>
              <G.Button onClick={() => ModalClick('Contratar')} style={{ backgroundColor: '#2E8B57' }}>
                Contratar
              </G.Button>
            </G.CardFooter>
          </G.Card>
        </G.Body>
      }

      {!loading &&
        <G.Body>
          {contract !== '' &&
            <G.Box>
              <G.BoxHeader style={{ height: '50px' }}>

                <G.BoxHeaderTitleArea>
                  <G.Image src={ selectedService === 'Cloud' ? '/cloud.svg' : selectedService === 'Suporte Servidor' ? '/server_rack.svg' : '' } style={{ height: '50px', marginRight: "7px" }} />
                  <G.SubTitle>{selectedService.toUpperCase()}</G.SubTitle>
                </G.BoxHeaderTitleArea>

                {(user.legal === '1' || user.manager === '1') &&
                  <G.ButtonOutLine onClick={ShowContract} style={{ border: '1px solid #ffb400', color: '#333' }}>
                    Contrato
                  </G.ButtonOutLine>
                }
              </G.BoxHeader>
              <G.BoxHeader style={{ height: '60px', marginTop: '8px', border: 'none' }}>
                <G.BoxHeaderOptionsArea style={{ flexDirection: 'column', alignItems: 'start', padding: '0px', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
                  <G.InfoArea>
                    <G.DistacInfoText style={{ marginTop: '-10px', backgroundColor: '#fff', padding: '0px 5px' }}>
                      Pagamento
                    </G.DistacInfoText>
                  </G.InfoArea>
                  <G.InfoArea style={{ height: '40px', padding: '3px 0px' }}>
                    <G.InfoArea>
                      <CalendarMonthIcon style={{ fontSize: '30px', marginRight: "5px" }} />
                      <G.DistacInfoText style={{ marginRight: "5px" }}>
                        Ciclo:
                      </G.DistacInfoText>
                      <G.InfoText>
                        {termDetails.billing_cicle_start} {termDetails.pay_type === "Pré-pago" || termDetails.billing_date > termDetails.billing_cicle_start ? 'do mês corrente à ' : 'do mês anterior à '}
                        {termDetails.billing_cicle_end} {termDetails.pay_type === "Pré-pago" || termDetails.billing_date > termDetails.billing_cicle_start ? 'do próximo mês' : 'do mês corrente'}
                      </G.InfoText>
                    </G.InfoArea>
                    <G.InfoArea>
                      <TodayIcon style={{ fontSize: '30px', marginRight: "5px" }} />
                      <G.DistacInfoText style={{ marginRight: "5px" }}>
                        Dia:
                      </G.DistacInfoText>
                      <G.InfoText>{termDetails.billing_date}</G.InfoText>
                    </G.InfoArea>
                    <G.InfoArea>
                      <MonetizationOnIcon style={{ fontSize: '30px', marginRight: "5px" }} />
                      <G.DistacInfoText style={{ marginRight: "5px" }}>
                        Método:
                      </G.DistacInfoText>
                      <G.InfoText>{termDetails.pay_method}</G.InfoText>
                    </G.InfoArea>
                  </G.InfoArea>
                </G.BoxHeaderOptionsArea>
                <G.BoxHeaderOptionsArea style={{ flexDirection: 'column', alignItems: 'start', padding: '0px', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
                  <G.InfoArea>
                    <G.DistacInfoText style={{ padding: '0px 5px', marginTop: '-10px', backgroundColor: '#fff' }}>
                      Atendimento
                    </G.DistacInfoText>
                  </G.InfoArea>
                  <G.InfoArea style={{ height: '40px', padding: '3px 0px' }}>
                    <G.InfoArea>
                      <SupportAgentIcon style={{ marginRight: "5px",  fontSize: '30px' }}/>
                      <G.DistacInfoText style={{ marginRight: "5px" }}>
                        Local:
                      </G.DistacInfoText>
                      <G.InfoText>{termDetails.local_service}</G.InfoText>
                    </G.InfoArea>
                    <G.InfoArea>
                      <SupportAgentIcon style={{ marginRight: "5px", fontSize: '30px' }}/>
                      <G.DistacInfoText style={{ marginRight: "5px" }}>
                        Remoto:
                      </G.DistacInfoText>
                      <G.InfoText>{termDetails.remote_service}</G.InfoText>
                    </G.InfoArea>
                  </G.InfoArea>
                </G.BoxHeaderOptionsArea>
              </G.BoxHeader>

              {/* CARDS - DEVICES - SERVICES*/}
              <G.BoxBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                {/* {termDetails !== [] && termServices !== [] && */}
                {(termDetails && termServices) &&
                  <>
                    {Object.keys(termServices).map((subArray, i) => (
                      <G.Card style={{ width: '75%' }}>
                        <G.CardHeader key={i} style={{ height: '40px' }}>
                          {/* <StorageIcon style={{marginLeft: '8px', marginRight: "7px",  fontSize: '30px' }} /> */}
                          <G.Image src={'/server.svg'} style={{ height: '44px', marginLeft: '1px', marginRight: "7px", }} />
                          <G.SmallTitle>{subArray}</G.SmallTitle>
                        </G.CardHeader>
                        {termServices[subArray].map((item, key) => (
                          <G.CardBody key={key} style={{ flexDirection: 'column', justifyContent: 'center', marginTop: '5px', borderBottom: '1px solid #e0e0e0' }}>

                            <G.InfoArea style={{ height: '50px', justifyContent: 'start', padding: '0px 8px', marginBottom: '0px', borderRadius: '0px' }}>
                              <G.DistacInfoText 
                                style={{ height: '10px', width: '15px', justifyContent: 'center', alignItems: 'center', padding: '5px', marginRight: '8px', border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#f9f9f9', color: '#333', cursor: 'pointer' }} 
                                onClick={() => setServiceDetails(serviceDetails === false ? true : false) + setServiceDetailsSelected(termServices[subArray][key])}
                              >
                                {serviceDetails === false ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
                              </G.DistacInfoText>
                              {item.service_icon !== null ?
                                <G.Image src={`/${item.service_icon}`} style={{ height: '40px', padding: '0px 2px' }} />
                                : <G.Image src={'/management-service.svg'} style={{ height: '28px', padding: '0px 2px' }} />
                              }
                              <G.DistacInfoText style={{ width: '600px', padding: '5px', fontSize: '13px' }}>
                                {item.service_name}
                              </G.DistacInfoText>
                              {/* {item.quantity !== 1 && */}
                                <G.InfoText style={{ width: '120px', justifyContent: 'end', padding: '5px 8px', fontSize: '13px' }}>
                                  {parseFloat(item.service_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </G.InfoText>
                              {/* } */}
                            </G.InfoArea>

                            {/* DETALHES DO SERVIÇO */}
                            {serviceDetails !== false &&
                              <G.InfoArea style={{ minHeight: '30px', padding: '0px 8px', marginBottom: '2px' }}>
                                <G.InfoArea style={{ width: '100%', minHeight: '30px', justifyContent: 'start', alignItems: 'center', padding: '10px', borderLeft: '4px solid #aaa', borderRadius: '3px', backgroundColor: '#f9f9f9' }}>
                                  <G.Text>
                                    {item.service_details}
                                  </G.Text>
                                </G.InfoArea>
                              </G.InfoArea>
                            }

                            <G.InfoArea style={{ height: '50px', justifyContent: 'center', padding: '0px 8px', marginBottom: '10px', borderRadius: '0px' }}>

                              <G.DistacInfoText style={{ height: '25px', width: '480px', justifyContent: 'space-around', padding: '5px', marginRight: '10px', border: item.service_quota_gb !== null ? '1px solid #e0e0e0' : 'none', borderRadius: '5px' }}>
                                
                                <G.InfoArea style={{ width: '210px', justifyContent: 'start' }}>
                                  {item.service_quota_gb !== null &&
                                    <>
                                      <StorageIcon style={{ fontSize: '25px', marginRight: '5px' }} />
                                      <G.DistacInfoText style={{ marginRight: '5px' }}>
                                        Armazenamento:
                                      </G.DistacInfoText>
                                      <G.InfoText style={{ fontWeight: 'normal' }}>{parseInt(item.service_quota_gb).toLocaleString('pt-br') + ' GB'}</G.InfoText>
                                    </>
                                  }
                                </G.InfoArea>

                                <G.InfoArea style={{ width: '115px', justifyContent: 'start' }}>
                                  {item.service_quota_accounts !== null &&
                                    <>
                                      <AllInboxIcon style={{ fontSize: '25px', marginRight: '5px' }} />
                                      <G.DistacInfoText style={{ marginRight: '5px' }}>
                                        Contas:
                                      </G.DistacInfoText>
                                      <G.InfoText style={{ fontWeight: 'normal' }}>{parseInt(item.service_quota_accounts).toLocaleString('pt-br')}</G.InfoText>
                                    </>
                                  }
                                </G.InfoArea>
                                
                                <G.InfoArea style={{ width: '90px', justifyContent: 'start'}}>
                                  {item.service_quota_accounts !== null &&
                                    <>
                                      <LanguageIcon style={{ fontSize: '25px', marginRight: '5px' }} />
                                      <G.DistacInfoText style={{ marginRight: '5px' }}>
                                        Dom.:
                                      </G.DistacInfoText>
                                      <G.InfoText style={{ fontWeight: 'normal' }}>05</G.InfoText>
                                    </>
                                  }
                                </G.InfoArea>

                              </G.DistacInfoText>
                              {/* <G.DistacInfoText style={{ height: '20px', width: '120px', justifyContent: 'end', padding: '10px 5px', marginRight: '10px', fontSize: '13px' }}>
                                Subtotal
                              </G.DistacInfoText> */}
                              <G.DistacInfoText style={{ height: '16px', width: '50px', justifyContent: 'center', padding: '10px 5px', marginRight: '7px', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                                {'x' + item.quantity}
                              </G.DistacInfoText>
                              <G.DistacInfoText style={{ height: '16px', width: '128px', justifyContent: 'end', padding: '10px 8px', borderLeft: '4px solid #aaa', borderRadius: '5px', backgroundColor: '#f9f9f9', fontSize: '13px' }}>
                                {parseFloat(item.service_subtotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </G.DistacInfoText>
                            </G.InfoArea>

                          </G.CardBody>
                        ))}
                        {/* CARD - VALORES*/}
                        <G.CardFooter style={{ justifyContent: 'end', marginTop: '20px',padding: '10px 15px' }}>
                          {/* VALOR - TOTAL */}
                          <G.InfoArea style={{ width: '150px', justifyContent: 'end', padding: '10px', marginRight: '15px', borderLeft: '4px solid #FCC703', borderRadius: '3px', backgroundColor: '#f5f5f5' }}>
                            <G.InfoText style={{ marginRight: '7px' }}>
                              Serviços:
                            </G.InfoText>
                            <G.DistacInfoText style={{ fontSize: '13px' }}>
                              {termServices[subArray].reduce((a, b) => a + parseFloat(b.service_subtotal), 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </G.DistacInfoText>
                          </G.InfoArea>
                          {/* VALOR - DESCONTO */}
                          <G.InfoArea style={{ width: '150px', justifyContent: 'end', padding: '10px', marginRight: '15px', borderLeft: '4px solid #E95839', borderRadius: '3px', backgroundColor: '#f5f5f5' }}>
                            <G.InfoText style={{ marginRight: '7px' }}>
                              Desconto:
                            </G.InfoText>
                            <G.DistacInfoText style={{ fontSize: '13px' }}>
                              {(termServices[subArray].reduce((a, b) => a + parseFloat(b.service_subtotal), 0) - termServices[subArray].reduce((a, b) => a + parseFloat(b.service_total), 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </G.DistacInfoText>
                          </G.InfoArea>
                          {/* VALOR - TOTAL - DESCONTO */}
                          <G.InfoArea style={{ width: '150px', justifyContent: 'end', padding: '10px', borderLeft: '4px solid #2E8B57', borderRadius: '3px', backgroundColor: '#f5f5f5' }}>
                            <G.InfoText style={{ marginRight: '7px' }}>
                              Total:
                            </G.InfoText>
                            <G.DistacInfoText style={{ fontSize: '13px' }}>
                              {termServices[subArray].reduce((a, b) => a + parseFloat(b.service_total), 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </G.DistacInfoText>
                          </G.InfoArea>
                        </G.CardFooter>
                        {/* CARD - BOTÕES: CANCELAR, DOWNGRADE, UPGRADE, SOLICITAR ALTERAÇÃO */}
                        <G.CardFooter style={{ justifyContent: 'end' }}>
                          <G.Button
                            style={{ backgroundColor: '#E95839' }}
                            onClick={() => ModalClick('Cancelar', 
                              termServices[subArray][0].service_type, 
                              termServices[subArray][0].service_quota_gb, 
                              termServices[subArray][0]) 
                              + setUpDownSelectedService(selectedService === 'Cloud' ? termServices[subArray][0].service_name : selectedService) 
                              + setConfirm(true)
                            }
                          >
                            Cancelar
                          </G.Button>
                          {selectedService === 'Cloud' &&
                            <>
                              <G.Button
                                style={{ backgroundColor: '#1E90FF' }}
                                onClick={() => ModalClick('Downgrade', 
                                  termServices[subArray][0].service_type, 
                                  termServices[subArray][0].service_quota_gb, 
                                  termServices[subArray][0])
                                }
                              >
                                Downgrade
                              </G.Button>
                              <G.Button
                                style={{ backgroundColor: '#2E8B57' }}
                                onClick={() => ModalClick('Upgrade', 
                                  termServices[subArray][0].service_type, 
                                  termServices[subArray][0].service_quota_gb, 
                                  termServices[subArray][0])
                                }
                              >
                                Upgrade
                              </G.Button>
                            </>
                          }
                          {(selectedService === 'Suporte Servidor') &&
                            <G.Button
                              style={{ width: '120px', backgroundColor: '#FCC703' }}
                              onClick={() => ModalClick('Solicitar Alteração') + setUpDownSelectedService(selectedService) + setConfirm(true)}
                            >
                              Solicitar Alteração
                            </G.Button>
                          }
                        </G.CardFooter>
                      </G.Card>
                    ))}
                  </>
                }

                {/* VALORES - CONTRATO */}
                <G.OptionsArea style={{ width: '100%', justifyContent: 'end', marginTop: '30px', marginBottom: '10px', padding: '10px 0px', border: '1px solid #e0e0e0', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                  <G.Text style={{ width: '210px', justifyContent: 'center', padding: '10px', fontSize: '15px', fontWeight: 'bold', color: '#333' }}>
                    CONTRATO
                  </G.Text>
                  <G.InfoArea style={{
                    width: '200px',
                    border: '1px solid #e0e0e0',
                    padding: '10px',
                    backgroundColor: '#fff',
                    marginRight: '14px'
                  }}>
                    <G.InfoText style={{ marginRight: '7px', fontSize: '13px' }}>
                      Contrato:
                    </G.InfoText>
                    <G.DistacInfoText style={{ fontSize: '13px' }}>
                      {contractSum.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </G.DistacInfoText>
                  </G.InfoArea>
                  <G.InfoArea style={{
                    width: '200px',
                    border: '1px solid #e0e0e0',
                    padding: '10px',
                    backgroundColor: '#fff',
                    marginRight: '14px'
                  }}>
                    <G.InfoText style={{ marginRight: '7px', fontSize: '13px' }}>
                      Desconto:
                    </G.InfoText>
                    <G.DistacInfoText style={{ fontSize: '13px' }}>
                      {(contractSum - contractDiscountSum).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </G.DistacInfoText>
                  </G.InfoArea>
                  <G.InfoArea style={{ width: '200px', border: '1px solid #e0e0e0', padding: '10px', backgroundColor: '#fff', marginRight: '14px' }}>
                    <G.InfoText style={{ marginRight: '7px', fontSize: '13px' }}>
                      Total:
                    </G.InfoText>
                    <G.DistacInfoText style={{ fontSize: '13px' }}>
                      {contractDiscountSum.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </G.DistacInfoText>
                  </G.InfoArea>
                </G.OptionsArea>
              </G.BoxBody>

              {(user.legal === '1' || user.manager === '1') &&
                <G.BoxFooter id='contract' >
                  <G.OptionsArea style={{ width: '100%', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '10px 0px', marginBottom: '10px', backgroundColor: '#f9f9f9', justifyContent: 'center' }}>
                    <G.InfoArea style={{ width: '25px', padding: '9px' }}>
                      <G.InfoText></G.InfoText>
                    </G.InfoArea>
                    <G.BoxHeaderOptionsArea>
                      <G.InfoArea >
                        <DescriptionIcon style={{ fontSize: '30px', border: '3px solid #000', borderRadius: '50%', padding: '7px' }} />
                        <G.DistacInfoText style={{ width: '80px', borderRight: '2px solid #000', margin: '7px', padding: '10px' }}>
                          CONTRATO
                        </G.DistacInfoText>
                        <G.InfoText>{contract.contract_id}</G.InfoText>
                      </G.InfoArea>
                      <G.InfoArea style={{ width: '180px', justifyContent: 'center' }}>
                        <G.InfoText style={{ backgroundColor: '#2E8B57', padding: '5px 15px', borderRadius: '5px', color: '#fff' }}>
                          {contract.status}
                        </G.InfoText>
                      </G.InfoArea>
                      <G.InfoArea>
                        <CheckIcon style={{
                          fontSize: '30px',
                          borderRadius: '50%',
                          padding: '2px',
                          border: contract.sign === null ? '3px solid #999' : '3px solid #2E8B57',
                          color: contract.sign === null ? '#999' : '#2E8B57'
                        }} />
                      </G.InfoArea>
                      <G.InfoArea style={{ width: '120px' }}>
                        <G.InfoText>{contract.sign_date === null ? '' : new Date(contract.sign_date).toLocaleDateString()}</G.InfoText>
                      </G.InfoArea>
                      <G.InfoArea style={{ width: '40px' }}>
                        {contract.sign !== null &&
                          <G.IconButton
                            onClick={() => { ClickDownload(contract.file) }}
                            style={{ backgroundColor: '#2E8B57' }}>
                            <DownloadIcon fontSize="small" />
                          </G.IconButton>
                        }
                      </G.InfoArea>
                    </G.BoxHeaderOptionsArea>
                  </G.OptionsArea>
                  {terms &&
                    <>
                      {terms.map((item, key) => (
                        <G.OptionsArea key={key} style={{ width: '100%', justifyContent: 'center', padding: '10px 0px' }}>
                          <G.InfoArea style={{ width: '25px', border: '2px solid #000', padding: '7px' }}>
                            <G.InfoText >{item.term_version}</G.InfoText>
                          </G.InfoArea>
                          <G.BoxHeaderOptionsArea>
                            <G.InfoArea >
                              <DescriptionIcon style={{ fontSize: '30px', border: '3px solid #000', borderRadius: '50%', padding: '7px' }} />
                              <G.DistacInfoText style={{ width: '80px', borderRight: '2px solid #000', margin: '7px', padding: '10px' }}>
                                TERMO
                              </G.DistacInfoText>
                              <G.InfoText>{item.term_name}</G.InfoText>
                            </G.InfoArea>
                            <G.InfoArea style={{ width: '180px', justifyContent: 'center' }}>
                              <G.InfoText style={{
                                padding: '5px 15px', borderRadius: '5px',
                                backgroundColor:
                                  item.term_status === 'Vigente' ? '#2E8B57' 
                                  :  item.term_status === 'Obsoleto' ? '#333' 
                                  :  item.term_status === 'Duplicado' ? '#FFB400' : '#999',
                                color: '#fff'
                              }}>{item.term_status}
                              </G.InfoText>
                            </G.InfoArea>
                            <G.InfoArea>
                              <CheckIcon style={{
                                fontSize: '30px',
                                borderRadius: '50%',
                                padding: '2px',
                                border: item.term_sign === null ? '3px solid #999' : '3px solid #2E8B57',
                                color: item.term_sign === null ? '#999' : '#2E8B57'
                              }} />
                            </G.InfoArea>
                            <G.InfoArea style={{ width: '120px' }}>
                              <G.InfoText >
                                {item.term_sign_date === null ? '' : new Date(item.term_sign_date).toLocaleDateString()}
                              </G.InfoText>
                            </G.InfoArea>
                            <G.InfoArea style={{ width: '40px' }}>
                              {item.term_sign !== null &&
                                <G.IconButton onClick={() => { ClickDownload(item.file) }} style={{ backgroundColor: '#2E8B57' }}>
                                  <DownloadIcon fontSize="small" />
                                </G.IconButton>
                              }
                            </G.InfoArea>
                          </G.BoxHeaderOptionsArea>
                        </G.OptionsArea>
                      ))}
                    </>
                  }
                </G.BoxFooter>
              }
            </G.Box>
          }
        </G.Body>
      }
      <G.Footer style={{ fontSize: '12px' }}>
        <p>Copyright <CopyrightIcon style={{ fontSize: '12px' }} /> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda.</p>
      </G.Footer>

      {/* MODAL - BOTÕES: CANCELAR, DOWNGRADE, UPGRADE, SOLICITAR ALTERAÇÃO */}
      {showModal !== '' &&
        <M.ModalContainer>
          <M.ModalContentLarge>

            {/* MODAL - CORPO - CONTEÚDO - SERVIÇOS - BACKUP CLOUD OU MAIL CLOUD OU SYSTEM CLOUD, DOWNGRAE OU UPGRADE */}
            {/* {selectedService === 'Cloud' &&  */}

            {(selectedService === 'Cloud' && confirm !== true && ticketNumber === null && loadingModal === false) &&
              <>
                {/* MODAL - CORPO - CONTEÚDO */}
                <G.BoxBody style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'center', padding: '10px', margin: '0px', overflowX: 'hidden' }}>
                  {/* MODAL - CORPO - CONTEÚDO - SERVIÇOS - DOWNGRAE, UPGRADE */}
                  {/* MODAL - CORPO - CONTEÚDO - CABEÇALHO - SERVIÇO ATUAL */}
                  <G.SmallTitle >
                    PLANO ATUAL
                  </G.SmallTitle>
                  <G.OptionsArea style={{ padding: '10px 0px', marginTop: '10px', marginBottom: '10px', borderBottom: showModal === 'Upgrade' ? '2px solid #2e8b57' : showModal === 'Downgrade' ? '2px solid #1e90ff' : '2px solid #d0d0d0' }}>

                    <G.InfoArea style={{
                      width: '364px',
                      height: '16px',
                      justifyContent: 'start',
                      padding: '10px',
                      marginRight: '10px',
                      border: '1px solid #d0d0d0',
                      backgroundColor: '#fff'
                    }}>
                      <G.Text>
                        {serviceActive.service_name}
                      </G.Text>
                    </G.InfoArea>

                    <G.InfoArea style={{
                      width: '96px',
                      height: '16px',
                      justifyContent: 'space-around',
                      padding: '10px',
                      marginRight: '10px',
                      border: '1px solid #d0d0d0',
                      backgroundColor: '#fff'
                    }}>
                      <StorageIcon style={{ fontSize: '20px', color: '#555' }} />
                      <G.Text>
                        {parseInt(serviceActive.service_quota_gb).toLocaleString('pt-BR') + ' GB'}
                      </G.Text>
                    </G.InfoArea>

                    {serviceType === 2 &&
                      <>
                        <G.InfoArea style={{
                          width: '64px',
                          height: '16px',
                          justifyContent: 'space-around',
                          padding: '10px',
                          marginRight: '10px',
                          border: '1px solid #d0d0d0',
                          backgroundColor: '#fff'
                        }}>
                          <AllInboxIcon style={{ fontSize: '20px', color: '#555' }} />
                          <G.Text>
                            {parseInt(serviceActive.service_quota_accounts).toLocaleString('pt-BR')}
                          </G.Text>
                        </G.InfoArea>

                        <G.InfoArea style={{
                          width: '56px',
                          height: '16px',
                          justifyContent: 'space-around',
                          padding: '10px',
                          marginRight: '10px',
                          border: '1px solid #d0d0d0',
                          backgroundColor: '#fff'
                        }}>
                          <LanguageIcon style={{ fontSize: '20px', color: '#555' }} />
                          <G.Text>
                            05
                          </G.Text>
                        </G.InfoArea>
                      </>
                    }

                    <G.InfoArea style={{
                      width: '96px',
                      height: '16px',
                      padding: '10px',
                      marginRight: '10px',
                      border: '1px solid #d0d0d0',
                      backgroundColor: '#fff'
                    }}>
                      <G.Text>
                        {parseFloat(serviceActive.service_subtotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                      </G.Text>
                    </G.InfoArea>

                    <G.InfoArea style={{ width: '90px', height: '20px', padding: '7px', margin: '0px 5px' }}>

                    </G.InfoArea>

                  </G.OptionsArea>

                  {/* MODAL - CORPO - CONTEÚDO - SERVIÇOS - DOWNGRADE - UPGRADE */}
                  <G.SmallTitle style={{ marginTop: '10px', marginBottom: '10px' }}>
                    ESCOLHA UM PLANO PARA {showModal.toUpperCase()}
                  </G.SmallTitle>
                  {showFilteredServices.map((item, key) => (
                    <G.OptionsArea key={key} style={{ marginTop: '3px', borderRadius: '7px' }}>

                      <G.InfoArea style={{
                        width: '364px',
                        height: '16px',
                        justifyContent: 'start',
                        padding: '10px',
                        marginRight: '10px',
                        border: '1px solid #e0e0e0',
                        backgroundColor: '#fff'
                      }}>
                        <G.Text>
                          {item.name}
                        </G.Text>
                      </G.InfoArea>

                      <G.InfoArea style={{
                        width: '96px',
                        height: '16px',
                        justifyContent: 'space-around',
                        padding: '10px',
                        marginRight: '10px',
                        border: '1px solid #e0e0e0',
                        backgroundColor: '#fff'
                      }}>
                        <StorageIcon style={{ fontSize: '20px', color: '#555' }} />
                        <G.Text>
                          {parseInt(item.quota_gb).toLocaleString('pt-BR') + ' GB'}
                        </G.Text>
                      </G.InfoArea>

                      {serviceType === 2 &&
                        <>
                          <G.InfoArea style={{
                            width: '64px',
                            height: '16px',
                            justifyContent: 'space-around',
                            padding: '10px',
                            marginRight: '10px',
                            border: '1px solid #e0e0e0',
                            backgroundColor: '#fff'
                          }}>
                            <AllInboxIcon style={{ fontSize: '20px', color: '#555' }} />
                            <G.Text>
                              {parseInt(item.quota_accounts).toLocaleString('pt-BR')}
                            </G.Text>
                          </G.InfoArea>

                          <G.InfoArea style={{
                            width: '56px',
                            height: '16px',
                            justifyContent: 'space-around',
                            padding: '10px',
                            marginRight: '10px',
                            border: '1px solid #e0e0e0',
                            backgroundColor: '#fff'
                          }}>
                            <LanguageIcon style={{ fontSize: '20px', color: '#555' }} />
                            <G.Text>
                              05
                            </G.Text>
                          </G.InfoArea>
                        </>
                      }

                      <G.InfoArea style={{
                        width: '96px',
                        height: '16px',
                        padding: '10px',
                        marginRight: '10px',
                        border: '1px solid #e0e0e0',
                        backgroundColor: '#fff'
                      }}>
                        <G.Text>
                          {parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </G.Text>
                      </G.InfoArea>

                      <G.Button
                        style={{ backgroundColor: showModal === 'Upgrade' ? '#2e8b57' : showModal === 'Downgrade' ? '#1e90ff' : '#333' }}
                        onClick={() => (UpDownClick(showFilteredServices[key].name) + setConfirm(true))}
                      >
                        {showModal}
                      </G.Button>

                    </G.OptionsArea>
                  ))}

                </G.BoxBody>
              </>
            }

            {/* MODAL - CORPO - CONTEÚDO - SERVIÇOS - OUTROS, DOWNGRAE OU UPGRADE */}
            <G.BoxBody>
              {(confirm === true || showModal === 'Contratar') &&
                <G.InfoArea style={{ flexDirection: 'column', width: '680px', justifyContent: 'center', padding: '15px', margin: '10px' }}>
                  {selectedService === 'Cloud' &&
                    <G.SmallTitle style={{ marginTop: '10px', marginBottom: '15px' }}>
                      Realizar o {showModal === 'Cancelar' ? 'Cancelamento do' : showModal === 'Upgrade' ? 'Upgrade para o' : showModal === 'Downgrade' ? 'Downgrade para o' : ''} plano:
                    </G.SmallTitle>
                  }
                  {selectedService === 'Suporte Servidor' &&
                    <G.SmallTitle style={{ marginTop: '10px', marginBottom: '15px' }}>
                      Realizar {showModal === 'Cancelar' ? 'o Cancelamento do' : showModal === 'Solicitar Alteração' ? 'a Solicitação de Alteração para o' : ''} plano:
                    </G.SmallTitle>
                  }
                  <G.InfoArea style={{
                    width: '100%',
                    height: '16px',
                    justifyContent: 'center',
                    padding: '10px',
                    marginRight: '10px',
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#fff',
                    fontWeight: 'bold'
                  }}>
                    {(selectedService === 'Cloud' && showModal === 'Cancelar') &&
                      <G.Text>
                        {serviceActive.service_name}
                      </G.Text>
                    }
                    {(selectedService === 'Suporte Servidor' && (showModal === 'Cancelar' || showModal === 'Solicitar Alteração')) &&
                      <G.Text>
                        {selectedService}
                      </G.Text>
                    }
                    {(showModal === 'Downgrade' || showModal === 'Upgrade') &&
                      <G.Text>
                        {upDownSelectedService}
                      </G.Text>
                    }

                  </G.InfoArea>

                  <G.SmallTitle style={{ marginTop: '15px' }}>
                    Um chamado será aberto para o atendimento de sua solicitação, deseja continuar?
                  </G.SmallTitle>
                </G.InfoArea>
              }
              {loadingModal && 
                <G.InfoArea style={{ width: '680px', justifyContent: 'center', alignItems: 'center', padding: '15px', margin: '10px' }}>
                  <G.Loading src={'/loading.svg'} style={{ height: '170px', marginTop: '0px' }} />
                </G.InfoArea>
              }
              {(confirm === false && ticketNumber !== null && loadingModal === false) &&
                <G.InfoArea style={{ flexDirection: 'column', width: '680px', justifyContent: 'center', padding: '15px', margin: '10px' }}>
                  {ticketNumber !== 'TicketCreateError' &&
                    <>
                      <G.SmallTitle style={{ marginTop: '10px', marginBottom: '15px' }}>
                        O chamado n.º
                      </G.SmallTitle>
                      <G.InfoArea style={{
                        width: '100%',
                        height: '16px',
                        justifyContent: 'center',
                        padding: '10px',
                        marginRight: '10px',
                        border: '1px solid #e0e0e0',
                        backgroundColor: '#fff',
                        fontWeight: 'bold'
                        }}>
                          {ticketNumber}
                      </G.InfoArea>
                      <G.SmallTitle style={{ marginTop: '15px' }}>
                        foi criado com sucesso.
                      </G.SmallTitle>
                    </>
                  }
                  {ticketNumber === 'TicketCreateError' &&
                    <>
                      <G.InfoArea style={{ flexDirection: 'column' }}>
                        <ErrorOutlineRoundedIcon style={{ fontSize: '56px', color: '#e95839' }}/>
                        <G.SmallTitle style={{ flexDirection: 'column' }}>
                          <p>Algo aconteceu de errado e seu chamado não foi criado. Por gentileza tente novamente.</p>
                          <p>Caso o erro persista contate o Administrador do sistema.</p>
                        </G.SmallTitle>
                      </G.InfoArea>
                    </>
                  }
                </G.InfoArea>
              }
            </G.BoxBody>

            {/* MODAL - RODAPÉ */}
            {loadingModal === false &&
              <G.BoxFooter style={{ flexDirection: 'row', padding: '15px 0px', marginTop: '0px', marginBottom: '0px' }}>
                <G.Button style={{ backgroundColor: '#e95839' }} onClick={() => (setShowModal('') + setUpDownSelectedService(false) + setConfirm(false) + setTicketNumber(null))}>
                  Fechar
                </G.Button>
                {(confirm === true || showModal === 'Contratar') &&
                  <G.Button style={{ margin: '0px 5px', backgroundColor: '#2e8b57' }} onClick={() => TicketClick() + setUpDownSelectedService(false) + setConfirm(false)}>
                    Sim
                  </G.Button>
                }
              </G.BoxFooter>
            }

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}
