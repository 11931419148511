import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment, { isDate } from 'moment'

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LogoutIcon from '@mui/icons-material/Logout';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import WarningIcon from '@mui/icons-material/Warning';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const Payments = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)
  
  const [category, setCategory] = useState([])
  const [accountPlan, setAccountPlan] = useState([])
  const [account, setAccount] = useState([])
  const [costCenter, setCostCenter] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])
  const [postingStatus, setPostingStatus] = useState([])
  const [entity, setEntity] = useState([])
  const [entityType, setEntityType] = useState([])
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [paymentStatus, setPaymentStatus] = useState('')
  const [file, setFile] = useState([])
  const [fileName, setFileName] = useState([])
  const [markDeleteFile, setMarkDeleteFile] = useState(false)

  // const year = new Date().getFullYear()
  // const month = new Date().getMonth() +1
  var firstday = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
  var lastday = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(firstday)
  const [endDate, setEndDate] = useState(lastday)

  // const [employees, setEmployees] = useState([])
  // const [suppliers, setSuppliers] = useState([])
  // const [customers, setCustomers] = useState([])
  // const [transportCompanies, setTransportCompanies] = useState([])
  // const [companies, setCompanies] = useState([])
  // const [lawyers, setLawyers] = useState([])

  const [valorBruto, setValorBruto] = useState('0')
  const [desconto, setDesconto] = useState('0')
  const [juros, setJuros] = useState('0')




  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')



/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.pgmt_descricao.toLowerCase().search(value) !== -1 
            || data.pgmt_entidade_nome.toLowerCase().search(value) !== -1 
            || data.pgmt_categoria.toLowerCase().search(value) !== -1 
            || data.pgmt_data_vencimento.toLowerCase().search(value) !== -1 
            || data.pgmt_valor_total.toLowerCase().search(value) !== -1 
            || data.pgmt_estado.toLowerCase().search(value) !== -1 
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
  


  /// FUNCTIONS ////////////////////////////////////////////////////////////////

  const AddClick = () => {
    setModalType(1)
    loadBases()
    setModal(true)
  }


  const EditClick = (key) => {
    setSelectedKey([key])
    setModalType(2)
    loadBases()
    setModal(true)
  }


  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    setValorBruto('0')
    setDesconto('0')
    setJuros('0')
    GetPayment()
    setModal(false)
    setModalType('')
    setFileName([])
    setMarkDeleteFile(false)
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    setValorBruto('0')
    setDesconto('0')
    setJuros('0')
    setModal(false)
    setModalType('')
    setFileName([])
    setMarkDeleteFile(false)
    reset()
    setLoading(false)
  }


  const GetPayment = async () => {
    setLoading(true)
    let res = await Api.getPaymentDate(startDate, endDate);
    //console.log("GetPayment > res: ", res)
    setList(res)
    setFilteredList(res)
    setLoading(false)
  }


  const DelPayment = async (id) => {
    let res = await Api.delPayment(id);
    GetPayment()
  }


  const loadBases =  async (value) => {
    let res1 = await Api.getPaymentCategory()
    setCategory(res1)
    let res2 = await Api.getAccountPlan()
    setAccountPlan(res2)
    let res3 = await Api.getAccount()
    setAccount(res3)
    let res4 = await Api.getCostCenter()
    setCostCenter(res4)
    let res5 = await Api.getPaymentMethod()
    setPaymentMethod(res5)
    let res6 = await Api.getPostingStatus()
    setPostingStatus(res6)
    let res7 = await Api.getEntity()
    setEntityType(res7)
  }
 
  
  const GetSelect =  async (value) => {
    setSelectedEntityId(value)
    if (value === '1') {
      let res = await Api.getEmployee()
      setEntity(res)
    } else if (value === '2') {
      let res = await Api.getSupplier()
      setEntity(res)
    } else if (value === '3') {
      let res = await Api.getCustomer()
      setEntity(res)
    } else if (value === '4') {
      let res = await Api.getTransportCompany()
      setEntity(res)
    } else if (value === "5") {
      let res = await Api.getCompany()
      setEntity(res)
      } else if (value === '6') {
      let res = await Api.getLawyer()
      setEntity(res)
    } else{}
  }
 

  // const onSubmit = async (data) => {
  //   if(file.length !== 0) {
  //     let res = await Api.uploadFile(file)
  //     data["pgmt_anexo"] = file.name
  //   } else {
  //   }
  //   if(markDeleteFile === true) {
  //     Delete(filteredList[selectedKey].pgmt_anexo)
  //     data["pgmt_anexo"] = null
  //   }
  //   if(modalType === 1) {
  //     let res = await Api.addPayment(data)
  //   } else if (modalType === 2) {
  //     let res = await Api.upgPayment(data)
  //   } else{}
  //   CloseModalClick()
  // };


  const onSubmit = async (data) => {
    //console.log("onSubmit > data: ", data)
    if(file.length !== 0) {
      let res = await Api.uploadFile(file)
      data["pgmt_anexo"] = file.name
    }
    if(markDeleteFile === true) {
      Delete(filteredList[selectedKey].pgmt_anexo)
      data["pgmt_anexo"] = null
    }

    switch (modalType) {
      case 1:
        const userAdd = ({
          pgmt_usuario_criacao: user.id,
          pgmt_usuario_atualizacao: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await Api.addPayment(add)
        //console.log("addPayment: ", res1)
      break;

      case 2:
        const userUpg = ({
          pgmt_usuario_atualizacao: user.id
        })
        const upg = ({...data, ...userUpg})
        let res2 = await Api.upgPayment(upg)
        //console.log("upgPayment: ", res2)
      break;

      default:
      break;
    }

    CloseModalClick()
  };

  
  const Download = (file) => {
    window.open(`${process.env.REACT_APP_BACKEND}/download?file=${file}`)
  }

  const Delete = async (file) => {
    // window.open(`${process.env.REACT_APP_BACKEND}/download?file=${file}`)
    if(file.length !== 0) {
      let res = await Api.deleteFile(file)
    } else {
    }
  }



/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetPayment();
  }, [state]);

  useEffect(()=>{ 
    
  }, [valorBruto, desconto, juros]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////



  
  


  return (
    <G.Container>

      <G.Header>

        <G.HeaderTitleArea style={{ width: '240px' }}>
          <LogoutIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px', transform: 'rotate(270deg)' }}/>
          <G.MainTitle>Pagamentos</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
        <G.InfoArea style={{ width: '100%', height: '30px', justifyContent: 'space-between', padding: '0px 5px' , color: '#222'}}>
            <G.Input type='date' value={startDate} style={{ backgroundColor: '#FBFBFB', border: '1px solid #e5e5e5', borderRadius: '10px' }} onChange={e => setStartDate(e.target.value)}/>
            <G.Input type='date' value={endDate} style={{ backgroundColor: '#FBFBFB', border: '1px solid #e5e5e5', borderRadius: '10px' }} onChange={e => setEndDate(e.target.value)} />
            {/* <PlayArrowRoundedIcon onClick={getPayment} style={{ fontSize: '32px', backgroundColor: '#f0f0f0', color: '#444', border: '1px solid #e5e5e5', padding: '2px', borderRadius: '10px', cursor: 'pointer' }}/> */}
            <G.PlayIconButton>
              <PlayArrowRoundedIcon onClick={GetPayment} style={{ fontSize: '32px' }}/>
            </G.PlayIconButton>
          </G.InfoArea>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>

      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>
              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'50px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('pgmt_id')}><G.DistacInfoText>ID</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'200px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('pgmt_descricao')}><G.DistacInfoText>Descrição</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_descricao') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'140px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('pgmt_entidade_nome')}><G.DistacInfoText>Entidade</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_entidade_nome') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('pgmt_categoria')}><G.DistacInfoText>Categoria</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_categoria') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('pgmt_data_vencimento')}><G.DistacInfoText>Vencimento</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_data_vencimento') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('pgmt_valor_total')}><G.DistacInfoText>Total</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_valor_total') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'84px'} style={{ cursor: 'pointer', padding: '0px 5px', margin: '0px 5px' }} onClick={() => OrderClick('pgmt_estado')}><G.DistacInfoText>Estado</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'pgmt_estado') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'92px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                {filteredList.length === 0 && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir para o período selecionado. ***
                  </G.Text>
                }
                {filteredList.length > 0 &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        <G.FieldArea size={'50px'}><G.Text>{item.pgmt_id}</G.Text></G.FieldArea>
                        <G.FieldArea size={'200px'}><G.Text>{item.pgmt_descricao}</G.Text></G.FieldArea>
                        <G.FieldArea size={'140px'}><G.Text>{item.pgmt_entidade_nome}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}><G.Text>{item.ctg_pgmt_descricao}</G.Text></G.FieldArea>
                        <G.FieldArea size={'100px'}><G.Text>{new Date(item.pgmt_data_vencimento).toLocaleDateString('pt-BR')}</G.Text></G.FieldArea>
                        <G.FieldArea size={'100px'}><G.Text>{(parseFloat(item.pgmt_valor_bruto) - parseFloat(item.pgmt_desconto_valor) + parseFloat(item.pgmt_juros_valor)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</G.Text></G.FieldArea>
                        <G.FieldArea size={'84px'} style={{ padding: '0px 5px', margin: '0px 5px' }}>
                          <C.ContactTypeIcon 
                            style={{ width: '80px', padding: '6px', fontSize: '11px', borderRadius: '5px', backgroundColor: item.pgmt_estado === 1 ? '#1E90FF' : item.pgmt_estado === 2 ? '#2E8B57' : item.pgmt_estado === 3 ? '#E95839' : item.pgmt_estado === 4 ? '#FFB400' : '#555' }}
                          >
                            {item.estd_lcmt_descricao}
                          </C.ContactTypeIcon>
                        </G.FieldArea>
                        <G.FieldArea size={'92px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key) + setFileName(filteredList[key].rcbmt_anexo)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>
            </G.Box>
          </>
        }

      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center'}}>

            <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : '#ccc', justifyContent: 'space-between', bordeRadius: '15px' }}>
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Pagamento' : modalType === 2 ? 'Editar Pagamento' : modalType === 3 ? 'Excluir Pagamento' : ''}</C.ModalTitle>
                <G.InfoArea>
                  <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer' ,color: '#333' }} onClick={CloseModalClick} />
                </G.InfoArea>
            </M.ModalHeader>

            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'start', marginTop: '5px'}}>

              {(modalType !== 1) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_id} {...register("pgmt_id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBodyScroll style={{ display: 'flex', margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Descrição
                    </G.FormInputLabel>
                    <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_descricao} {...register("pgmt_descricao")} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Categoria
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_categoria", { setValueAs: v => v === '' ? null : v })}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_categoria}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].ctg_pgmt_descricao}</option>
                      {category.map(i => (
                        <option key={i.ctg_pgmt_id} value={i.ctg_pgmt_id}>{i.ctg_pgmt_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.BreakLine/>

                  {/* <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Classificação
                  </div>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel>
                      Plano de Contas
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_plano_contas", { setValueAs: v => v === '' ? null : v })}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_plano_contas}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].pln_cnt_descricao}</option>
                      {accountPlan.map(i => (
                        <option key={i.pln_cnt_id} value={i.pln_cnt_id}>{i.pln_cnt_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel>
                      Centro de Custo
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_centro_custo", { setValueAs: v => v === '' ? null : v })}>
                      <option value={modalType !== 1 ? filteredList[selectedKey].pgmt_centro_custo : ''}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].cntr_cst_descricao}</option>
                      {costCenter.map(i => (
                        <option key={i.cntr_cst_id} value={i.cntr_cst_id}>{i.cntr_cst_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.BreakLine/> */}

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Dados Recebimento
                  </div>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                      Pagamento
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_forma_pagamento")}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_forma_pagamento}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].frm_pgmt_descricao}</option>
                      {paymentMethod.map(i => (
                        <option key={i.frm_pgmt_id} value={i.frm_pgmt_id}>{i.frm_pgmt_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                      Conta
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_conta", { setValueAs: v => v === '' ? null : v })}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_conta}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].cnt_descricao}</option>
                      {account.map(i => (
                        <option key={i.cnt_id} value={i.cnt_id}>{i.cnt_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px' }} /> */}

                  <G.FormInputArea style={{ width: '232px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Valor Bruto
                    </G.FormInputLabel>
                    <G.FormInput name={valorBruto} defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].pgmt_valor_bruto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("pgmt_valor_bruto" , {
                                setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})} onChange={(e) => setValorBruto(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Desconto
                    </G.FormInputLabel>
                    <G.FormInput name={desconto} defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].pgmt_desconto_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("pgmt_desconto_valor" , {
                                setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})} onChange={(e) => setDesconto(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #E95839' }}>
                      Juros
                    </G.FormInputLabel>
                    <G.FormInput name={juros} defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].pgmt_juros_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("pgmt_juros_valor" , {
                                setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})} onChange={(e) => setJuros(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '232px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Total
                    </G.FormInputLabel>
                    <G.FormInput disabled={true} style={{ color: '#000' }} value={modalType === 1 ? (Number(valorBruto) - Number(desconto) + Number(juros)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})
                                  : (Number(valorBruto !== '0' ? valorBruto : filteredList[selectedKey].pgmt_valor_bruto) - Number(desconto !== '0' ? desconto : filteredList[selectedKey].pgmt_desconto_valor) + Number(juros !== '0' ? juros : filteredList[selectedKey].pgmt_juros_valor)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} />
                  </G.FormInputArea>

                  <G.BreakLine style={{ height: '12px' }}/>

                  <G.FormInputArea style={{ width: '250px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Vencimento
                    </G.FormInputLabel>
                    <G.FormInput type="date" defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].pgmt_data_vencimento).format('YYYY-MM-DD')} {...register("pgmt_data_vencimento")} />
                  </G.FormInputArea>
                  <div style={{ width: '226px' }} />
                  <G.FormInputArea style={{ width: '250px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Compensação
                    </G.FormInputLabel>
                    <G.FormInput type="date" defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].pgmt_data_compensacao).format('YYYY-MM-DD')} {...register("pgmt_data_compensacao", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Entidade
                  </div>

                  <G.FormInputArea style={{ width: '380px' }}>
                    <G.FormInputLabel>
                      Entidade
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_entidade_id")} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_entidade_id} onChange={(e) => GetSelect(e.target.value)}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_entidade_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].entd_descricao}</option>
                      {entityType.map(i => (
                        <option key={i.entd_id} value={i.entd_id}>{i.entd_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>
                  {/* <ArrowForwardIosIcon/> */}
                  <G.FormInputArea style={{ width: '572px' }}>
                    {/* <G.FormInputLabel>
                      {modalType === 1 ? 'Entidade' : filteredList[selectedKey].entd_descricao}
                    </G.FormInputLabel> */}
                    <G.FormSelect {...register("pgmt_entidade_nome")}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_entidade_nome}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].pgmt_entidade_nome}</option>
                      {entity.map(i => (
                        <option 
                          key={
                            selectedEntityId === '1' ? i.fcnr_id 
                            : selectedEntityId === '2' ? i.fncdr_id 
                            : selectedEntityId === '3' ? i.clnt_id 
                            : selectedEntityId === '5' ? i.emprs_id 
                            : selectedEntityId === '6' ? i.adv_id : undefined
                          } 
                          value={
                            selectedEntityId === '1' ? i.fcnr_nome 
                            : selectedEntityId === '2' ? i.fncdr_nome 
                            : selectedEntityId === '3' ? i.clnt_nome 
                            : selectedEntityId === '5' ? i.emprs_nome 
                            : selectedEntityId === '6' ? i.adv_nome : undefined
                          }
                       >
                          {
                            selectedEntityId === '1' ? i.fcnr_nome 
                            : selectedEntityId === '2' ? i.fncdr_nome 
                            : selectedEntityId === '3' ? i.clnt_nome 
                            : selectedEntityId === '5' ? i.emprs_nome 
                            : selectedEntityId === '6' ? i.adv_nome : undefined
                          }
                        </option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>
                  
                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Documento Fiscal
                  </div>

                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel>
                      Tipo Doc Fiscal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_tipo_doc_fiscal} {...register("pgmt_tipo_doc_fiscal", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '376px' }}>
                    <G.FormInputLabel>
                      Cod Doc Fiscal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_cod_doc_fiscal} {...register("pgmt_cod_doc_fiscal", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel>
                      Série Doc Fiscal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_serie_doc_fiscal} {...register("pgmt_serie_doc_fiscal", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Estado Lançamento
                  </div>

                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel style={{ 
                        borderLeft: modalType === 1 
                          ? '' 
                          : filteredList[selectedKey].pgmt_estado === 1 
                          ? '3px solid #1E90FF' 
                          : filteredList[selectedKey].pgmt_estado === 2 
                          ? '3px solid #2E8B57' 
                          : filteredList[selectedKey].pgmt_estado === 3 
                          ? '3px solid #E95839' 
                          : filteredList[selectedKey].pgmt_estado === 4 
                          ? '3px solid #FFB400' 
                          : '#555' 
                    }}>
                      Estado
                    </G.FormInputLabel>
                    <G.FormSelect {...register("pgmt_estado")}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].pgmt_estado}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].estd_lcmt_descricao}</option>
                      {postingStatus.map(i => (
                        <option key={i.estd_lcmt_id} value={i.estd_lcmt_id} onChange={() => setPaymentStatus(i.estd_lcmt_id)}>{i.estd_lcmt_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Anexo
                  </div>

                  {modalType === 1 && 
                    <G.FormInputArea style={{ width: '380px' }}>
                      {/* <G.FormInputLabel>
                        Anexo
                      </G.FormInputLabel> */}
                      <G.Input type="file" accept=".pdf" style={{ width: '100%', borderRadius: '0px 5px 5px 0px', backgroundColor: '#F5F5F5' }} onChange={e => setFile(e.target.files[0])} />
                    </G.FormInputArea>
                  }
                  {modalType !== 1 && 
                    <>
                      {filteredList[selectedKey].pgmt_anexo === null &&
                        <G.FormInputArea style={{ width: '380px' }}>
                          {/* <G.FormInputLabel>
                            Anexo
                          </G.FormInputLabel> */}
                          <G.Input type="file" accept=".pdf" style={{ width: '100%', borderRadius: '0px 5px 5px 0px', backgroundColor: '#F5F5F5' }} onChange={e => setFile(e.target.files[0])} />
                        </G.FormInputArea>
                      }
                      {filteredList[selectedKey].pgmt_anexo !== null && 
                        // <G.FormInputArea style={{ width: '500px', cursor: 'pointer' }} onClick={() => Download(filteredList[selectedKey].pgmt_anexo)}>
                        //   <G.FormInputLabel>
                        //     {filteredList[selectedKey].pgmt_anexo}
                        //   </G.FormInputLabel>
                        // </G.FormInputArea>
                        <>
                          <G.BoxBody>
                            <G.FakeButton style={{ minWidth: '070px', backgroundColor: markDeleteFile === true ? '#aaa' : '#E95839' }}>PDF</G.FakeButton>
                            <G.FakeButton style={{ minWidth: '240px', marginLeft: '-20px', backgroundColor: markDeleteFile === true ? '#888' : '#34495e' }}>{filteredList[selectedKey].pgmt_anexo}</G.FakeButton>
                            <G.DownloadButton style={{ minWidth: '030px', marginLeft: '-20px', backgroundColor: markDeleteFile === true ? '#aaa' : '#2E8B57' }} onClick={() => Download(filteredList[selectedKey].pgmt_anexo)}><FileDownloadIcon /></G.DownloadButton>
                          </G.BoxBody>
                          <G.BoxBody>
                            <G.IconButton style={{ backgroundColor: markDeleteFile === false ? '#E95839' : '#1E90FF', margin: '0px 1px' }} onClick={() => setMarkDeleteFile(markDeleteFile === false ? true : false)}>
                              {markDeleteFile === false ? <DeleteIcon fontSize="small"/> : <ClearIcon fontSize="small"/>}
                            </G.IconButton>
                          </G.BoxBody>
                        </>
                      }
                    </>
                  }

                  <G.BreakLine style={{ height: '12px' }}/>

                  
                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ border: 'none', width: '936px', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observações
                    </G.FormInputLabel>
                    <G.TextArea {...register("pgmt_observacao", { setValueAs: v => v === '' ? null : v })} 
                          style={{ width: '916px', height: '150px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {modalType === 1 ? '' :  filteredList[selectedKey].pgmt_observacao}
                    </G.TextArea>
                  </G.FieldArea>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <>
                {/* <M.ModalContentSmall> */}

                  {/* <M.ModalHeader style={{ backgroundColor: '#FCAC9B', justifyContent: 'space-between', bordeRadius: '15px' }}>
                    <C.ModalTitle>{'Excluir Recebimento'}</C.ModalTitle>
                    <G.InfoArea>
                      <HighlightOffIcon onClick={CloseModalClick} style={{ color: '#333' }} fontSize="large" />
                    </G.InfoArea>
                  </M.ModalHeader> */}

                  <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <G.Text style={{ margin: '10px 0px' }}>
                      Deseja excluir o Recebimento:
                    </G.Text>
                    <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                      <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                        {filteredList[selectedKey].pgmt_descricao}
                      </G.FormInputLabel>
                    </G.FormInputArea>
                  </M.ModalBody>

                  {/* <M.ModalFooter>
                    <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                    <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelPayment(filteredList[selectedKey].pgmt_id) + CloseModalClick()}>Sim</G.Button>
                  </M.ModalFooter> */}

                {/* </M.ModalContentSmall> */}
              </>
            }


            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelPayment(filteredList[selectedKey].pgmt_id) + CloseModalClick()}>Sim</G.Button>
                </>
              }

            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}

