import styled from 'styled-components';

export const Container = styled.div`
display: flex;
height: 100vh;
`;

export const Sider = styled.div`
display: flex;
flex-direction: column;

`;


export const MenuHeader = styled.div`
background-color: #333;
color: #fff;
font-size: 25px;
width: 240px;
height: 70px;
`;

export const MenuBody = styled.div`
display: flex;
background-color: #ccc;
width: 240px;
flex: 1;
`;

export const Body = styled.div`
display: flex;
flex-direction: column;
flex: 1;
background-color: #fff;
`;

export const Header = styled.div`
background-color: #333;
color: #fff;
font-size: 24px;
height: 80px;
border-bottom: 2px solid #F0F0F0;
`;

export const PageBody = styled.div`
display: flex;
flex: 1;
margin-top: 30px;
background-color: #fff;
overflow-y: scroll;
scrollbar-width: thin;
&::-webkit-scrollbar {
    width: 2px;
    heigth: 2px;
}
&::-webkit-scrollbar-thumb {
    background-color: #333;
}
`;
