export const initialState = {
	id: '',
	user: '',
	name: '',
	email: '',
	foto: '',
	adm: '',
	cal: '',
	aux: '',
};

export const UserReducer = (state, action) => {

	switch (action.type) {
		case 'setUser':
		return { ...state, 
			id: action.payload.id, 
			user: action.payload.user, 
			name: action.payload.name,
			email: action.payload.email,
			foto: action.payload.foto,
			adm: action.payload.adm,
			cal: action.payload.cal,
			aux: action.payload.aux,
		}

		default:
		return state;
	}
}