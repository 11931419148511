/// API ////////////////////////////////////////////////////////////////////////
const GPP_API = process.env.REACT_APP_BACKEND;





///////////////////////////////////////////////////////////////////////////////

export default {


	/// - LOGIN - //////////////////////////
	/// ------------------------------------

	checkToken: async () => {
		const token = await localStorage.getItem('SessionID');
		const req = await fetch(`${GPP_API}/session?token=${token}`)
		//console.log('API > checkToken > req: ', req);
		const response = await req.json();
		//console.log('API > checkToken > response: ', response);
		return response;
	},

	login: async (CustomerUserLogin, Password) => {
		const req = await fetch(`${GPP_API}/auth`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				'login' : CustomerUserLogin,
				'password': Password
			})
		});
		const response = await req.json();
		//console.log(response)      
		return response;
	},

	renew: async (login, pw) => {
		const req = await fetch(`${GPP_API}/renew`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				{
					"login": login,
					"pw": pw
				}
			)
		});
		const response = await req.json();        
		return response;
	},

	getMail: async (login) => {
		const req = await fetch(`${GPP_API}/renew?login=${login}`)
		const response = await req.json();        
		return response;
	},

	sendMail: async (mailDest, mailBody) => {
		//console.log('Destino: ', mailDest, 'Mensagem: ', mailBody)
		const req = await fetch(`${GPP_API}/sendMail`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				{
					"dest": mailDest,
					"subject": 'Token para criação de nova senha',
					"body": mailBody,
				}
			)
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - ARQUIVOS - ///////////////////////
	/// ------------------------------------

	uploadFile: async (file) => {
		let formData = new FormData()
		formData.append('file', file)
		const req = await fetch(`${GPP_API}/upload`, {
			method: 'POST',
			headers: {
				Accept: 'multipart/form-data',
			},
			body: formData
		})
		return req;
	},

	deleteFile: async (file) => {
		const req = await fetch(`${GPP_API}/delete?file=${file}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------

	/// - PROC - PROCESSOS - ///////////////
	/// ------------------------------------

	getProcessAlert: async () => {
		const req = await fetch(`${GPP_API}/getProcessAlert`)
		const response = await req.json();
		return response;
	},

	getProcessInfoData: async (type) => {
		const req = await fetch(`${GPP_API}/getProcessInfoData?type=${type}`)
		const response = await req.json();
		return response;
	},

	getProcessPay: async (type, month) => {
		const req = await fetch(`${GPP_API}/getProcessPay?type=${type}month=${month}`)
		const response = await req.json();
		return response;
	},

	getOrderPay: async () => {
		const req = await fetch(`${GPP_API}/getOrderPay`)
		const response = await req.json();
		return response;
	},

	getCardProcess: async (types, state, userId) => {
		//console.log('Type: ', types, 'State: ', state, 'User Id: ', userId)
		const req = await fetch(`${GPP_API}/getCardProcess?${types}&${state}&${userId}`);
		const response = await req.json();
		return response
	},

	getArchProcess: async (year) => {
		const req = await fetch(`${GPP_API}/getProcess?year=${year}`);
		const response = await req.json();
		return response
	},

	getArchProcessCriteria: async (item, value) => {
		const req = await fetch(`${GPP_API}/getProcess?item=${item}&value=${value}`);
		const response = await req.json();
		return response
	},

	addProcess: async (postData) => {
		const req = await fetch(`${GPP_API}/addProcess`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgProcess: async (postData) => {
		const req = await fetch(`${GPP_API}/upgProcess`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();      
		return response;
	},

	delProcess: async (id) => {
		const req = await fetch(`${GPP_API}/delProcess?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - FASES - ///////////////////
	/// ------------------------------------

	getPhase: async () => {
		const req = await fetch(`${GPP_API}/getPhase`);
		const response = await req.json();
		return response;
	},

	addPhase: async (postData) => {
		const req = await fetch(`${GPP_API}/addPhase`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgPhase: async (postData) => {
		const req = await fetch(`${GPP_API}/upgPhase`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delPhase: async (id) => {
		const req = await fetch(`${GPP_API}/delPhase?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - ATIVIDADES - //////////////
	/// ------------------------------------

	getActivity: async () => {
		const req = await fetch(`${GPP_API}/getActivity`)
		const response = await req.json();
		return response;
	},

	addActivity: async (postData) => {
		const req = await fetch(`${GPP_API}/addActivity`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgActivity: async (postData) => {
		const req = await fetch(`${GPP_API}/upgActivity`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delActivity: async (id) => {
		const req = await fetch(`${GPP_API}/delActivity?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - FASES-PROCESSO - //////////
	/// ------------------------------------

	getProcessPhase: async (codProcess) => {
		const req = await fetch(`${GPP_API}/getProcessPhase?idProcesso=${codProcess}`)
		const response = await req.json();
		return response;
	},

	addProcessPhase: async (postData) => {
		const req = await fetch(`${GPP_API}/addProcessPhase`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgProcessPhase: async (postData) => {
		const req = await fetch(`${GPP_API}/upgProcessPhase`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();      
		return response;
	},

	delProcessPhase: async (id) => {
		const req = await fetch(`${GPP_API}/delProcessPhase?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - ATIVIDADES-FASE - /////////
	/// ------------------------------------

	getActivityPhase: async (idFase) => {
		const req = await fetch(`${GPP_API}/getActivityPhase?idFase=${idFase}`)
		const response = await req.json();
		return response;
	},

	getActivityPhase2: async (idFase) => {
		const req = await fetch(`${GPP_API}/getActivityPhase2`)
		const response = await req.json();
		return response;
	},

	getPhaseActivity: async (idFase) => {
		const req = await fetch(`${GPP_API}/getPhaseActivity`)
		const response = await req.json();
		return response;
	},

	addPhaseActivity: async (postData) => {
		const req = await fetch(`${GPP_API}/addPhaseActivity`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	upgPhaseActivity: async (postData) => {
	const req = await fetch(`${GPP_API}/upgPhaseActivity`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	delPhaseActivity: async (id) => {
		const req = await fetch(`${GPP_API}/delPhaseActivity?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - COMENTÁRIOS - /////////////
	/// ------------------------------------

	getComment: async (codFase) => {
		const req = await fetch(`${GPP_API}/getComment?idFase=${codFase}`)
		const comentariosGet = await req.json();
		return comentariosGet;
	},

	addComment: async (postData) => {
		const req = await fetch(`${GPP_API}/addComment`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const session = await req.json();
		return session;
	},

	/// ------------------------------------


	/// - PROC - CTRL-ATIVIDADES - /////////
	/// ------------------------------------

	getCtrlActivity: async (idFase) => {
		const req = await fetch(`${GPP_API}/getCtrlActivity?idFase=${idFase}`)
		const response = await req.json();
		return response;
	},

	addCtrlActivity: async (postData) => {
			const req = await fetch(`${GPP_API}/addCtrlActivity`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgCtrlActivity: async (postData) => {
		const req = await fetch(`${GPP_API}/upgCtrlActivity`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - ADVOGADOS - ///////////////
	/// ------------------------------------

	getLawyer: async () => {
		const req = await fetch(`${GPP_API}/getLawyer`);
		const response = await req.json();
		return response;
	},

	addLawyer: async (postData) => {
		const req = await fetch(`${GPP_API}/addLawyer`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgLawyer: async (postData) => {
		const req = await fetch(`${GPP_API}/upgLawyer`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delLawyer: async (id) => {
		const req = await fetch(`${GPP_API}/delLawyer?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response ;
	},

	/// ------------------------------------


	/// - PROC - EMPRESAS - ////////////////
	/// ------------------------------------

	getCompany: async () => {
		const req = await fetch(`${GPP_API}/getCompany`);
		const response = await req.json();
		return response ;
	},

	addCompany: async (postData) => {
		const req = await fetch(`${GPP_API}/addCompany`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	upgCompany: async (postData) => {
		const req = await fetch(`${GPP_API}/upgCompany`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delCompany: async (id) => {
		const req = await fetch(`${GPP_API}/delCompany?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - PROC - USUÁRIOS - ////////////////
	/// ------------------------------------

	getUser: async () => {
		const req = await fetch(`${GPP_API}/getUser`);
		const response = await req.json();
		return response;
	},

	addUser: async (postData) => {
		const req = await fetch(`${GPP_API}/addUser`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgUser: async (postData) => {
		const req = await fetch(`${GPP_API}/upgUser`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delUser: async (id) => {
		const req = await fetch(`${GPP_API}/delUser?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response ;
	},

	/// ------------------------------------




	/// - FIN - RECEBIMENTOS - /////////////
	/// ------------------------------------

	getReceiptDate: async (startDate, endDate) => {
		const req = await fetch(`${GPP_API}/getReceiptDate?dataInicial=${startDate}&dataFinal=${endDate}`);
		const response = await req.json();
		return response
	},

	addReceipt: async (postData) => {
		const req = await fetch(`${GPP_API}/addReceipt`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	upgReceipt: async (postData) => {
		const req = await fetch(`${GPP_API}/upgReceipt`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delReceipt: async (id) => {
		const req = await fetch(`${GPP_API}/delReceipt?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - PAGAMENTOS - ///////////////
	/// ------------------------------------

	getPaymentDate: async (startDate, endDate) => {
		const req = await fetch(`${GPP_API}/getPaymentDate?dataInicial=${startDate}&dataFinal=${endDate}`);
		const response = await req.json();
		return response
	},

	addPayment: async (postData) => {
		const req = await fetch(`${GPP_API}/addPayment`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgPayment: async (postData) => {
		const req = await fetch(`${GPP_API}/upgPayment`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
			});
		const response = await req.json();
		return response;
	},

	delPayment: async (id) => {
		const req = await fetch(`${GPP_API}/delPayment?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - BANCOS - ///////////////////
	/// ------------------------------------

	getBank: async () => {
		const req = await fetch(`${GPP_API}/getBank`)
		const response = await req.json();
		return response;
	},

	addBank: async (postData) => {
		const req = await fetch(`${GPP_API}/addBank`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},
	
	upgBank: async (postData) => {
		const req = await fetch(`${GPP_API}/upgBank`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
	},

	delBank: async (id) => {
		const req = await fetch(`${GPP_API}/delBank?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - FIN - TIPOS-CONTA - //////////////
	/// ------------------------------------

	getAccountType: async () => {
		const req = await fetch(`${GPP_API}/getAccountType`)
		const response = await req.json();
		return response;
	},

	addAccountType: async (postData) => {
		const req = await fetch(`${GPP_API}/addAccountType`, {
		method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	upgAccountType: async (postData) => {
		const req = await fetch(`${GPP_API}/upgAccountType`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delAccountType: async (id) => {
		const req = await fetch(`${GPP_API}/delAccountType?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - FIN - CONTA - ////////////////////
	/// ------------------------------------

	getAccount: async () => {
		const req = await fetch(`${GPP_API}/getAccount`)
		const response = await req.json();
		return response;
	},

	addAccount: async (postData) => {
		const req = await fetch(`${GPP_API}/addAccount`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgAccount: async (postData) => {
		const req = await fetch(`${GPP_API}/upgAccount`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delAccount: async (id) => {
		const req = await fetch(`${GPP_API}/delAccount?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},

	/// ------------------------------------


	/// - FIN - FORMAS-PAGAMENTO - /////////
	/// ------------------------------------

	getPaymentMethod: async () => {
		const req = await fetch(`${GPP_API}/getPaymentMethod`)
		const response = await req.json();
		return response;
	},

	addPaymentMethod: async (postData) => {
		const req = await fetch(`${GPP_API}/addPaymentMethod`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgPaymentMethod: async (postData) => {
		const req = await fetch(`${GPP_API}/upgPaymentMethod`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delPaymentMethod: async (id) => {
		const req = await fetch(`${GPP_API}/delPaymentMethod?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - ESTADOS-LANCAMENTO - ///////
	/// ------------------------------------

	getPostingStatus: async () => {
		const req = await fetch(`${GPP_API}/getPostingStatus`)
		const response = await req.json();
		return response;
	},

	addPostingStatus: async (postData) => {
		const req = await fetch(`${GPP_API}/addPostingStatus`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgPostingStatus: async (postData) => {
		const req = await fetch(`${GPP_API}/upgPostingStatus`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delPostingStatus: async (id) => {
		const req = await fetch(`${GPP_API}/delPostingStatus?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CATEG-RECEBIMENTO - ////////
	/// ------------------------------------

	getReceiptCategory: async () => {
		const req = await fetch(`${GPP_API}/getReceiptCategory`)
		const response = await req.json();
		return response;
	},

	addReceiptCategory: async (postData) => {
		const req = await fetch(`${GPP_API}/addReceiptCategory`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgReceiptCategory: async (postData) => {
		const req = await fetch(`${GPP_API}/upgReceiptCategory`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delReceiptCategory: async (id) => {
		const req = await fetch(`${GPP_API}/delReceiptCategory?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CATEG-PAGAMENTO - //////////
	/// ------------------------------------

	getPaymentCategory: async () => {
		const req = await fetch(`${GPP_API}/getPaymentCategory`)
		const response = await req.json();
		return response;
	},

	addPaymentCategory: async (postData) => {
		const req = await fetch(`${GPP_API}/addPaymentCategory`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgPaymentCategory: async (postData) => {
		const req = await fetch(`${GPP_API}/upgPaymentCategory`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delPaymentCategory: async (id) => {
		const req = await fetch(`${GPP_API}/delPaymentCategory?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - PLANO-CONTAS - /////////////
	/// ------------------------------------

	getAccountPlan: async () => {
		const req = await fetch(`${GPP_API}/getAccountPlan`)
		const response = await req.json();
		return response;
	},

	addAccountPlan: async (postData) => {
		const req = await fetch(`${GPP_API}/addAccountPlan`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgAccountPlan: async (postData) => {
		const req = await fetch(`${GPP_API}/upgAccountPlan`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delAccountPlan: async (id) => {
		const req = await fetch(`${GPP_API}/delAccountPlan?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CENTROS-CUSTO - ////////////
	/// ------------------------------------

	getCostCenter: async () => {
		const req = await fetch(`${GPP_API}/getCostCenter`)
		const response = await req.json();
		return response;
	},

	addCostCenter: async (postData) => {
		const req = await fetch(`${GPP_API}/addCostCenter`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgCostCenter: async (postData) => {
		const req = await fetch(`${GPP_API}/upgCostCenter`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delCostCenter: async (id) => {
		const req = await fetch(`${GPP_API}/delCostCenter?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - ENTIDADES - ////////////////
	/// ------------------------------------

	getEntity: async () => {
		const req = await fetch(`${GPP_API}/getEntity`)
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - TIPOS-ENTIDADE - ///////////
	/// ------------------------------------

	getEntitieType : async () => {
		const req = await fetch(`${GPP_API}/getEntitieType`)
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - FUNCIONARIOS - /////////////
	/// ------------------------------------

	getEmployee: async () => {
		const req = await fetch(`${GPP_API}/getEmployee`)
		const response = await req.json();
		return response;
	},

	addEmployee: async (postData) => {
		const req = await fetch(`${GPP_API}/addEmployee`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgEmployee: async (postData) => {
		const req = await fetch(`${GPP_API}/upgEmployee`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delEmployee: async (id) => {
		const req = await fetch(`${GPP_API}/delEmployee?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - CLIENTES - /////////////////
	/// ------------------------------------

	getCustomer: async () => {
		const req = await fetch(`${GPP_API}/getCustomer`)
		const response = await req.json();
		return response;
	},

	addCustomer: async (postData) => {
		const req = await fetch(`${GPP_API}/addCustomer`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgCustomer: async (postData) => {
		const req = await fetch(`${GPP_API}/upgCustomer`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delCustomer: async (id) => {
		const req = await fetch(`${GPP_API}/delCustomer?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - FORNECEDORES - /////////////
	/// ------------------------------------

	getSupplier: async () => {
		const req = await fetch(`${GPP_API}/getSupplier`)
		const response = await req.json();
		return response;
	},

	addSupplier: async (postData) => {
		const req = await fetch(`${GPP_API}/addSupplier`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgSupplier: async (postData) => {
		const req = await fetch(`${GPP_API}/upgSupplier`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delSupplier: async (id) => {
		const req = await fetch(`${GPP_API}/delSupplier?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - TRANSPORTADORES - //////////
	/// ------------------------------------

	getTransportCompany: async () => {
		const req = await fetch(`${GPP_API}/getTransportCompany`)
		const response = await req.json();
		return response;
	},

	addTransportCompany: async (postData) => {
		const req = await fetch(`${GPP_API}/addTransportCompany`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},
	
	upgTransportCompany: async (postData) => {
		const req = await fetch(`${GPP_API}/upgTransportCompany`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
				postData
			)
		});
		const response = await req.json();
		return response;
	},

	delTransportCompany: async (id) => {
		const req = await fetch(`${GPP_API}/delTransportCompany?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();
		return response;
	},

	/// ------------------------------------


	/// - FIN - TIPOS-CONTRIBUINTE - ///////
	/// ------------------------------------

	getTaxpayerType: async (idFase) => {
		const req = await fetch(`${GPP_API}/getTaxpayerType`)
		const response = await req.json();
		return response;
	},


	/// ------------------------------------


	/// - FIN - INTEGRAÇÃO - ///////////////
	/// ------------------------------------

	getIntegration: async () => {
		const req = await fetch(`${GPP_API}/Integration`)
		const response = await req.json();
		return response;
	},

	
	addIntegration: async (postData) => {
		const req = await fetch(`${GPP_API}/Integration`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(
			postData
		)
		});
		const response = await req.json();
		return response;
		},


	delIntegration: async (id) => {
		const req = await fetch(`${GPP_API}/Integration?id=${id}`, {
			method: 'DELETE'
		});
		const response = await req.json();        
		return response;
	},


	tokenInter: async () => {
		const req = await fetch(`${GPP_API}/inter?nome=inter`)
		const response = await req.json();
		return response;
	},

	/// ------------------------------------



};
