import styled from 'styled-components';


export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex: 1;
`;

export const Header = styled.div`
display: flex;
width: 990px;
min-height: 60px;
background-color: #f9f9f9;
border-radius: 5px;
align-items: center;
justify-content: space-between;
margin: 20px 5px 5px 5px;
padding: 0px 15px;
box-shadow: 0px 0px 3px #ccc;

`;

export const TitleArea = styled.div`
height: 40px;
padding: 5px;
display: flex;
align-items: center;
justify-content: start;
`;

export const StateArea = styled.div`
background-color: #f1f1f1;
height: 40px;
border-radius: 10px;
padding: 0px 15px;
margin-right: 15px;
display: flex;
align-items: center;
width: 130px;
justify-content: space-around;

`;

const Tooltip = styled.div`

  &:hover::after {
    margin-bottom: -60px;
    opacity: 1;
    pointer-events: all;
  }

  &::after {
    margin-left: 15px;
    padding: 7px;
    border-radius: 5px;
    opacity: 0;
    border: 1px solid orange;
    content: attr(aria-label);
    pointer-events: all;
    position: absolute;
    transition: 0.5s ease-in-out;
    background-color: #fff;
    margin-bottom: 0px;
  }
`;

export const SwitchArea = styled.div`
background-color: #f1f1f1;
height: 40px;
border-radius: 10px;
padding: 0px 5px;
display: flex;
align-items: center;
width: 110px;
justify-content: space-around;
margin-right: 15px;
`;

export const IconButton = styled.div`
padding: 7px;
width: 20px;
height: 20px;
color: #000;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 50%;
box-shadow: 0px 1px 1px #000;
background-color: #fff;
&:hover {
    background-color: #BDF5D6;
  }
`;


export const SearchArea = styled.div`
color: #777;
height: 50px;
padding: 5px;
display: flex;
align-items: center;
justify-content: center;
`;

export const SearchInput = styled.input`
width: 200px;
font-size: 14px;
border: none;
outline: none;
background-color: #f1f1f1;
height: 35px;
border-radius: 15px;
padding: 0px 15px;
font-family: inherit;
`;


export const Body = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;

`;

export const CardFilter = styled.div`
display: flex;
padding: 10px;
background-color: #f9f9f9;
border-radius: 5px;
align-items: center;
justify-content: center;
margin: 5px;
width: 1000px;

`;

export const Card = styled.div`
display: flex;
width: 490px;
min-height: 335px;
background-color: #fff;
border-radius: 5px;
margin: 20px;
border-radius: 5px;
flex-direction: column;
box-shadow: 0px 0px 5px #ccc;

`;

export const CardHeader = styled.div`
display: flex;
height: 50px;
background-color: #f9f9f9;
border-radius: 5px 5px 0px 0px;
align-items: center;
justify-content: start;
padding: 0px 10px;
`;

export const CardHeaderLogo = styled.div`
display: flex;
align-items: center;
justify-content: center;
color: #000;
`;

export const CardHeaderInfo = styled.div`
display: flex;
align-items: start;
justify-content: center;
flex-direction: column;
padding: 0px 5px;
width: 500px;
`;

export const CardHeaderNumber = styled.div`
align-items: center;
justify-content: start;
font-size: 13px;
font-weight: bold;
color: #000;
display: flex;
`;

export const CardHeaderTitle = styled.div`
align-items: center;
justify-content: center;
font-size: 15px;
color: #000;
margin-top: 1px;
`;

export const CardBody = styled.div`
display: flex;
height: 240px;
margin-top: 5px;
`;

export const CardBodyA = styled.div`
display: flex;
flex-direction: column;
flex: 1;
align-items: start;
justify-content: center;
margin: 0px 10px;

`;

export const CardBodyB = styled.div`
display: flex;
width: 200px;
flex-direction: column;
align-items: start;
justify-content: center;
border-left: 1px solid #DDD;
`;

export const CardBodyText = styled.div`
display: flex;
font-size: 12px;
margin: 5px;
padding: 10px;
border-radius: 5px;
background-color: #f5f5f5;
width: 240px;
justify-content: flex-start;

`;

export const CardBodyStatusText = styled.div`
align-items: center;
font-size: 12px;
margin: 3px 3px;
border-radius: 5px;
`;

export const CardFooter = styled.div`
display: flex;
justify-content: center;
align-items: start;
margin-top: 5px;
margin-bottom: 5px;
background-color: #fff;
flex: 1;
padding: 5px;
border-top: 1px solid #DDD;
`;

export const ExtraFooter = styled.div`
display: flex;
justify-content: end;
align-items: center;
margin-bottom: 5px;
background-color: #fff;
flex: 1;
padding: 5px;
`;

export const Logo = styled.img`
width: auto;
height: 170px;
margin-top: 150px;
`;


export const ActsBox = styled.div`
cursor: pointer;
padding: 3px 0px;

`;








//#########################################MODAL

export const ModalContainer = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
top: 0;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: 10;

`;

export const Content = styled.div`
background-color: #fff;
height: 550px;
width: 1060px;
border-radius: 10px;
box-shadow: 0px 0px 5px #ccc;
display: flex;
justify-content: start;
align-items: center;
padding: 10px;
margin-bottom: 20px;
flex-direction: column;
overflow-x: hidden;
overflow-y: scroll;
scrollbar-width: thin;
&::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
&::-webkit-scrollbar-thumb {
    background-color: #1E90FF;
`;

export const Options = styled.div`
background-color: #f9f9f9;
box-shadow: 0px 0px 5px #ccc;
margin-left: 630px;
height: 40px;
border-radius: 10px;
display: flex;
justify-content: end;
align-items: center;
padding: 10px;
margin-bottom: -50px;
box-shadow: 0px 0px 3px #ccc;
z-index: 3;
`;


export const ModalDetails = styled.div`
display: flex;
width: 217px;
height: 400px;
border: 1px solid #f1f1f1;
border-radius: 5px;
align-items: start;
justify-content: space-around;
margin: 40px 15px 5px 5px;
flex-direction: column;

`;

export const ModalContentBody = styled.div`
display: flex;
width: 750px;
background-color: #fff;
border-radius: 5px;
align-items: center;
justify-content: start;
margin: 5px 5px 5px 5px;
flex-direction: column;
`;

export const ModalBody = styled.div`
display: flex;
flex: 1;
`;

export const ArticleHeader = styled.div`
display: flex;
width: 750px;
height: 90px;
background-color: #f9f9f9;
border-radius: 5px 5px 0px 0px;
align-items: center;
justify-content: start;
box-shadow: 0px 0px 3px #ccc;

`;

export const ArticleBody = styled.div`
width: 730px;
border-radius: 5px 5px 0px 0px;
padding: 50px;
font-size: 14px;
color: #777;

`;

export const ArticleFooter = styled.div`
display: flex;
width: 750px;
min-height: 30px;
background-color: #fcfcfc;
border-radius: 5px 5px 0px 0px;
align-items: center;
justify-content: space-around;
border: 1px solid #f1f1f1;
padding: 15px 0px;

`;

export const ArticleFilesArea = styled.div`
display: flex;
width: 700px;
background-color: none;
align-items: center;
justify-content: end;
padding: 3px 0px;
font-size: 15px;
`;





export const Input = styled.input`
    font-size: 14px;
    margin-top: 10px;
    width: 500px;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    font-family: inherit;
    outline: none;
    color: #333;
`;


export const ArticleReply = styled.textarea`
width: 95%;
font-size: 14px;
border: none;
color: #333;
outline: none;
background-color: #F6FEFF;
height: 200px;
resize: none;
padding: 20px
scrollbar-width: thin;
font-family: inherit;
   font-size: inherit;
   &::-webkit-scrollbar {
    width: 2px;
    heigth: 2px;
}
&::-webkit-scrollbar-thumb {
    background-color: #333;   
`;

export const Article = styled.div`
display: flex;
width: 750px;
background-color: #fff;
border-radius: 5px;
align-items: center;
justify-content: start;
margin: 10px 10px 20px 10px;
flex-direction: column;
border: 1px solid #f1f1f1;
`;

export const Button = styled.div`
padding: 7px;
width: 110px;
height: 20px;
color: #fff;
border-radius: 7px;
font-weight: bold;
box-shadow: 0px 2px 2px #ccc;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: 0px 5px;
`;
