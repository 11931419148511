import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from 'moment'

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ConstructionIcon from '@mui/icons-material/Construction';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';


/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';





export const Suppliers = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  
  const [entitieTypes, setEntitieTypes] = useState([])
  const [taxpayerTypes, setTaxpayerTypes] = useState([])
  const [selectedType, setSelectedType] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)
  
  const [empty, setEmpty] = useState(null)
  const [stateErrors, setStateErros] = useState('');



  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')


  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.fncdr_nome.toLowerCase().search(value) !== -1 
            || data.fncdr_end_cidade.toLowerCase().search(value) !== -1 
            || data.fncdr_end_uf_sigla.toLowerCase().search(value) !== -1 
            || data.fncdr_end_pais.toLowerCase().search(value) !== -1 
            || data.fncdr_tel_fixo.toLowerCase().search(value) !== -1 
            //|| data.fncdr_tp_entd_id.search(value) !== -1
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }

  
  /// MASKS ////////////////////////////////////////////////////////////////////

  const [cnpjNumber, setCnpjNumber] = useState('')
  const onChangeCnpj = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['999.999.999/9999-99']);
    setCnpjNumber(unMask(maskedValue));
  }

  const [cpfNumber, setCpfNumber] = useState('')
  const onChangeCpf = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['999.999.999-99']);
    setCpfNumber(unMask(maskedValue));
  }

  const [rgNumber, setRgNumber] = useState('')
  const onChangeRg = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99.999.999-9']);
    setRgNumber(unMask(maskedValue));
  }

  const [cepNumber, setCepNumber] = useState('')
  const onChangeCep = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99999-999']);
    setCepNumber(unMask(maskedValue));
  }

  const [phoneNumber, setPhoneNumber] = useState('')
  const onChangePhone = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999']);
    setPhoneNumber(unMask(maskedValue));
  }

  const [cellNumber, setCellNumber] = useState('')
  const onChangeCell = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);
    setCellNumber(unMask(maskedValue));
  }



  /// FUNCTIONS ////////////////////////////////////////////////////////////////

  const AddClick = () => {
    setModalType(1)
    loadBases()
    setModal(true)
  }  


  const EditClick = (key) => {
    setSelectedKey([key])

    setCnpjNumber(filteredList[key].fncdr_cnpj);
    setCpfNumber(filteredList[key].fncdr_cpf);
    setRgNumber(filteredList[key].fncdr_rg);
    setCepNumber(filteredList[key].fncdr_end_cep);
    setPhoneNumber(filteredList[key].fncdr_tel_fixo);
    setCellNumber(filteredList[key].fncdr_tel_movel);

    setModalType(2)
    loadBases()
    setModal(true)
  }


  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }


  const ActClick = (key) => {
    setSelectedKey([key])
    setModalType(4)
    setModal(true)
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    GetSupplier()
    setCnpjNumber('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setStateErros(false)
    setModal(false)
    setSelectedType('')
    setModalType('')
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    setCnpjNumber('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setStateErros(false)
    setModal(false)
    setSelectedType('')
    setModalType('')
    reset()
    setLoading(false)
  }

  const loadBases =  async () => {
    let res1 = await Api.getEntitieType();
    console.log("getEntitieType: ", res1);
    setEntitieTypes(res1);
    let res2 = await Api.getTaxpayerType();
    console.log("getTaxpayerType: ", res2);
    setTaxpayerTypes(res2);
  }
 

  const GetSupplier = async () => {
    setEmpty(false)
    setLoading(true)
    let res = await Api.getSupplier();
    if (res.length > 0) {
      setEmpty(false)
      setList(res)
      setFilteredList(res)
    } else {
        setEmpty(true)
    }
    setLoading(false)
  }


  const DelSupplier = async (id) => {
    let res = await Api.delSupplier(id);
    GetSupplier();
  }


  // const ActSupplier = async (id, estado) => {
  //   let res = await Api.upgSupplier({fncdr_id: id, fncdr_estado: estado});
  //   GetSupplier();
  // }


  const ActSupplier = async (id, estado) => {
    const data = ({
      fncdr_id: id, 
      fncdr_estado: estado, 
      fncdr_atualizacao_usuario: user.id
    })
    let res = await Api.upgSupplier(data);
    GetSupplier();
  }

  
  // const onSubmit = async (data) => {
  //   data["fncdr_cpf"] = cpfNumber
  //   data["fncdr_rg"] = rgNumber
  //   data["fncdr_tel_movel"] = cellNumber
  //   data["fncdr_cnpj"] = cnpjNumber
  //   data["fncdr_tel_fixo"] = phoneNumber
  //   data["fncdr_end_cep"] = cepNumber
  //   //console.log(data)

  //   if (
  //     (selectedType === '1' && (cpfNumber === '' || cpfNumber === null || rgNumber === '' || rgNumber === null || cellNumber === '' || cellNumber === null))
  //     ||
  //     (selectedType === '2' && (cnpjNumber === '' || cnpjNumber === null || phoneNumber === '' || phoneNumber === null))
  //     || 
  //     (cepNumber === '' || cepNumber === null)
  //   ) {setStateErros(true)}
  //   else {
  //     setStateErros(false)

  //     console.log("onSubmit > Data : ", data)

  //     if(modalType === 1) {
  //       let res1 = await Api.addSupplier(data)
  //       //console.log("res1: ", res1)
  //     }
  //      if (modalType === 2) {
  //       let res2 = await Api.upgSupplier(data)
  //       //console.log("res2: ", res2)
  //     }
  //   }
  //   CloseModalClick()
  // };


  const onSubmit = async (data) => {
    data["fncdr_cpf"] = cpfNumber
    data["fncdr_rg"] = rgNumber
    data["fncdr_tel_movel"] = cellNumber
    data["fncdr_cnpj"] = cnpjNumber
    data["fncdr_tel_fixo"] = phoneNumber
    data["fncdr_end_cep"] = cepNumber
    //console.log("onSubmit > data: ", data)

    if (
      (selectedType === '1' && (cpfNumber === '' || cpfNumber === null || rgNumber === '' || rgNumber === null || cellNumber === '' || cellNumber === null))
      ||
      (selectedType === '2' && (cnpjNumber === '' || cnpjNumber === null || phoneNumber === '' || phoneNumber === null))
      || 
      (cepNumber === '' || cepNumber === null)
    ) {setStateErros(true)}
    else {
      setStateErros(false)

    switch (modalType) {
      case 1:
        const userAdd = ({
          fncdr_criacao_usuario: user.id,
          fncdr_atualizacao_usuario: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await Api.addSupplier(add)
        //console.log("addSupplier > res1 : ", res1)
        break;

      case 2:
        const userUpg = ({
          fncdr_atualizacao_usuario: user.id
        })
        const upg = ({...data, ...userUpg})
        let res2 = await Api.upgSupplier(upg)
        //console.log("upgSupplier > res2 : ", res2)
        break;

      default:
        break;
    }

    CloseModalClick()

    }
  };





  
/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetSupplier();
  }, [state]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////
  //console.log(selectedType);

  // console.log("CNPJ: ", cnpjNumber);
  // console.log("CPF: ",cpfNumber);
  // console.log("RG: ",rgNumber);
  // console.log("CEP: ",cepNumber);
  // console.log("TEL: ",phoneNumber);
  // console.log("CEL: ",cellNumber);
  



////////////////////////////////////////////////////////////////////////////////





  return (
    <G.Container>

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <ConstructionIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
          <G.MainTitle>Fornecedores</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>
      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>

              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('fncdr_tp_entd_id')}><G.DistacInfoText>Tipo</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'fncdr_tp_entd_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'240px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('fncdr_nome')}><G.DistacInfoText>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'fncdr_nome') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'150px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('fncdr_end_cidade')}><G.DistacInfoText>Cidade</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'fncdr_end_cidade') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'40px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('fncdr_end_uf_sigla')}><G.DistacInfoText>UF</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'fncdr_end_uf_sigla') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'150px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('fncdr_end_pais')}><G.DistacInfoText>País</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'fncdr_end_pais') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('fncdr_tel_fixo')}><G.DistacInfoText>Telefone</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'fncdr_tel_fixo') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              {(loading === false && filteredList.length > 0) &&
                <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                  {/* {(loading === false && filteredList.length === 0) && 
                    <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                      *** Não existem itens a exibir. ***
                    </G.Text>
                  } */}
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        {/* <G.FieldArea size={'35px'}><G.Text><C.ContactTypeIconArea style={{ border:  item.cnt_estado === 1 ? '3px solid #2E8B57' : '3px solid #E95839' }}>
                          <AccountBalanceWalletIcon style={{ fontSize: '23px', color: '#000' }} /></C.ContactTypeIconArea></G.Text>
                        </G.FieldArea> */}
                        <G.FieldArea size={'100px'}><G.Text>{item.tp_entd_descricao}</G.Text></G.FieldArea>
                        <G.FieldArea size={'240px'}><G.Text>{item.fncdr_nome}</G.Text></G.FieldArea>
                        <G.FieldArea size={'150px'}><G.Text>{item.fncdr_end_cidade}</G.Text></G.FieldArea>
                        <G.FieldArea size={'40px'}><G.Text>{item.fncdr_end_uf_sigla}</G.Text></G.FieldArea>
                        <G.FieldArea size={'150px'}><G.Text>{item.fncdr_end_pais}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}><G.Text>{mask(item.fncdr_tel_fixo, ['(99) 9999-9999'])}</G.Text></G.FieldArea>
                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key) + setSelectedType((filteredList[key].fncdr_tp_entd_id).toString())} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}>
                            <EditIcon fontSize="small" />
                          </G.IconButton>
                          <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.fncdr_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>
                            {item.fncdr_estado === 0 ? <CheckIcon fontSize="small"/> : item.fncdr_estado === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}
                          </G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}>
                            <DeleteIcon fontSize="small"/>
                          </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                </G.TableBody>
              }
            </G.Box>
          </>
        }
      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center'}}>

            <M.ModalHeader 
              style={{ 
                backgroundColor
                  : modalType === 1 ? '#BDF5D6' 
                  : modalType === 2 ? '#C8E4FF' 
                  : modalType === 3 ? '#FCAC9B' 
                  : (filteredList[selectedKey].fncdr_estado === 0 ? '#BDF5D6' : '#ccc'), 
                justifyContent: 'space-between', 
                bordeRadius: '15px' 
              }}
            >
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Fornecedor' : modalType === 2 ? 'Editar Fornecedor' : modalType === 3 ? 'Excluir Fornecedor' : (filteredList[selectedKey].fncdr_estado === 0 ? 'Ativar Fornecedor' : 'Desativar Fornecedor')}</C.ModalTitle>
                <G.InfoArea>
                  <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                </G.InfoArea>
            </M.ModalHeader>

            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'start', marginTop: '5px'}}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" readOnly={true} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_id} {...register("fncdr_id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBodyScroll style={{ display: 'flex', margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInputArea style={{ width: '260px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Tipo
                    </G.FormInputLabel>
                    <G.FormSelect 
                      {...register("fncdr_tp_entd_id", { required: true })}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <option autoFocus disabled selected value={modalType === 1 ? '' : filteredList[selectedKey].fncdr_tp_entd_id}>
                        {modalType === 1 ? 'Selecione' : filteredList[selectedKey].tp_entd_descricao}
                      </option>
                      {entitieTypes.map(i => (
                        <option key={i.tp_entd_id} value={i.tp_entd_id}>{i.tp_entd_descricao}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '690px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Nome
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_nome} {...register("fncdr_nome", { required: true })} />
                  </G.FormInputArea>

                  {/* TIPO ENTIDADE – PESSOA JURÍDICA */}
                  {selectedType === '2' && 
                    <>
                      <G.FormInputArea style={{ width: '260px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          CNPJ
                        </G.FormInputLabel>
                        {/* <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_cnpj} {...register("fncdr_cnpj", { required: selectedType === '2' ? true : false }, { setValueAs: v => v === '' ? null : v })} /> */}
                        <G.FormInput 
                          type='text'
                          name='cnpj'
                          placeholder=''
                          value={
                            cnpjNumber === null 
                            ? '' 
                            : mask(cnpjNumber, ['999.999.999/9999-99'])
                          }
                          onChange={ selectedType === '2' ? onChangeCnpj : setCnpjNumber(null)}

                          // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_cnpj} 
                          // {...register("fncdr_cnpj", { required: selectedType === '2' ? true : false }, { setValueAs: v => v === '' ? null : v })} 
                        />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '690px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                          Razão Social
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_razao_social} {...register("fncdr_razao_social", { required: selectedType === '2' ? true : false }, { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '240px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Tipo Contribuinte
                        </G.FormInputLabel>
                          <G.FormSelect {...register("fncdr_tipo_contribuinte", { setValueAs: v => v === '' ? null : v })}>
                            <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_tipo_contribuinte}>
                              {modalType === 1 ? 'Selecione' : filteredList[selectedKey].fncdr_tipo_contribuinte}
                            </option>
                            {taxpayerTypes.map(i => (
                              <option key={i.tp_ctrbt_descricao} value={i.tp_ctrbt_descricao}>{i.tp_ctrbt_descricao}</option>
                            ))}
                          </G.FormSelect>
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '240px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Inscr. Estadual
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_inscr_estadual} {...register("fncdr_inscr_estadual", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '222px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Inscr. Municipal
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_inscr_municipal} {...register("fncdr_inscr_municipal", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '222px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Suframa
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_suframa} {...register("fncdr_suframa", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>
                    </>
                  }

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}
                  
                  {/* TIPO ENTIDADE – PESSOA FÍSICA */}
                  {selectedType === '1' && 
                    <>
                      <G.FormInputArea style={{ width: '260px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          CPF
                        </G.FormInputLabel>
                        <G.FormInput 
                          type='text'
                          name='cpf'
                          placeholder=''
                          value={
                            cpfNumber === null 
                            ? '' 
                            : mask(cpfNumber, ['999.999.999-99'])
                          }
                          onChange={selectedType === '1' ? onChangeCpf : setCpfNumber(null)}
                          // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_cpf} 
                          // {...register("fncdr_cpf", { required: selectedType === '1' ? true : false }, { setValueAs: v => v === '' ? null : v })} 
                        />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '260px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          RG
                        </G.FormInputLabel>
                        <G.FormInput 
                          type='text'
                          name='rg'
                          placeholder=''
                          value={
                            rgNumber === null 
                            ? '' 
                            : mask(rgNumber, ['99.999.999-9'])
                          }
                          onChange={onChangeRg}
                          // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_rg} 
                          // {...register("fncdr_rg", { setValueAs: v => v === '' ? null : v })} 
                        />
                      </G.FormInputArea>
                    </>
                  }

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  {/* TIPO ENTIDADE – ESTRANGEIRA || TIPO ENTIDADE – OUTRA */}
                  {(selectedType === '3' || selectedType === '4') && 
                    <>
                      <G.FormInputArea style={{ width: '390px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                        Tipo Documento
                        </G.FormInputLabel>
                        <G.FormInput 
                          defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_tipo_documento} 
                          {...register("fncdr_tipo_documento", { required: (selectedType === '3' || selectedType === '4') ? true : false }, { setValueAs: v => v (selectedType !== '3' && selectedType !== '4' && (v === '' || v === null)) ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '440px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Código Documento
                        </G.FormInputLabel>
                        <G.FormInput 
                          defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_documento} 
                          {...register("fncdr_documento", { required: (selectedType === '3' || selectedType === '4') ? true : false }, { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>
                    </>
                  }

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FormInputArea style={{ width: '592px' }}>
                    <G.FormInputLabel>
                      Endereço
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_endereco} {...register("fncdr_end_endereco", { required: true }, { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '360px' }}>
                    <G.FormInputLabel>
                      Complemento
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_complemento} {...register("fncdr_end_complemento", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '480px' }}>
                    <G.FormInputLabel>
                      Bairro
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_bairro} {...register("fncdr_end_bairro", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '180px' }}>
                    <G.FormInputLabel>
                      CEP
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='cep'
                      placeholder='Código Postal'
                      value={
                        cepNumber === null 
                        ? '' 
                        : mask(cepNumber, ['99999-999'])
                      }
                      onChange={onChangeCep}
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_cep} 
                      // {...register("fncdr_end_cep", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '480px' }}>
                    <G.FormInputLabel>
                      Cidade
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_cidade} {...register("fncdr_end_cidade", { required: true }, { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '120px' }}>
                    <G.FormInputLabel>
                      UF
                    </G.FormInputLabel>
                    <G.FormInput maxLength={2} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_uf_sigla} {...register("fncdr_end_uf_sigla", { required: true }, { setValueAs: v => v === '' ? null : v.toUpperCase() })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '340px' }}>
                    <G.FormInputLabel>
                      País
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_end_pais} {...register("fncdr_end_pais", { required: true }, { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FormInputArea style={{ width: '344px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Contato
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_cnt_rpsvl} {...register("fncdr_cnt_rpsvl", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Telefone
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='telefone'
                      placeholder=''
                      value={
                        phoneNumber === null 
                        ? '' 
                        : mask(phoneNumber, ['(99) 9999-9999'])
                      }
                      onChange={onChangePhone}
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_tel_fixo} 
                      // {...register("fncdr_tel_fixo", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '140px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Ramal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_tel_ramal} {...register("fncdr_tel_ramal", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Celular
                    </G.FormInputLabel>
                    <G.FormInput
                      type='text'
                      name='celular'
                      placeholder=''
                      value={
                        cellNumber === null 
                        ? '' 
                        : mask(cellNumber, ['(99) 9 9999-9999'])
                      }
                      onChange={onChangeCell} 
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_tel_movel} 
                      // {...register("fncdr_tel_movel", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '300px' }}>
                    <G.FormInputLabel>
                      E-mail
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_email} {...register("fncdr_email", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '340px' }}>
                    <G.FormInputLabel>
                      Web Site
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_website} {...register("fncdr_website", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '300px' }}>
                    <G.FormInputLabel>
                      Responsável
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_responsavel} {...register("fncdr_responsavel", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ border: 'none', width: '936px', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observações
                    </G.FormInputLabel>
                    <G.TextArea {...register("fncdr_observacao", { setValueAs: v => v === '' ? null : v })} 
                          style={{ width: '916px', height: '150px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {modalType === 1 ? '' :  filteredList[selectedKey].fncdr_observacao}
                    </G.TextArea>
                  </G.FieldArea>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <>
                <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <G.Text style={{ margin: '10px 0px' }}>
                    Deseja excluir o Fornecedor:
                  </G.Text>
                  <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                    <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                      {filteredList[selectedKey].fncdr_nome}
                    </G.FormInputLabel>
                  </G.FormInputArea>
                </M.ModalBody>
              </>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].fncdr_estado === 0 ? 'ativar' : 'desativar'} o Fornecedor:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].fncdr_nome}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelSupplier(filteredList[selectedKey].fncdr_id) + CloseModalClick()}>Sim</G.Button>
                </>
              }
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActSupplier(filteredList[selectedKey].fncdr_id, filteredList[selectedKey].fncdr_estado === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }

            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}

