import React, {useState, useEffect, useContext} from 'react'
import { Outlet, useNavigate } from "react-router-dom";

import { MainHeader } from '../../container/Header';
import { SideBar } from '../../container/SideBar';
import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import * as C from './styled'


export const Layout = () => {

	const navigate = useNavigate()
	const { dispatch } = useContext(UserContext);

	const [loading, setloading] = useState(true)


	useEffect(()=>{
		const checkLogin = async () => {
			const res1 = await localStorage.getItem('SessionID')
			//console.log('Container > Layout > checkLogin : res1', res1)
			if(res1){
				let res2 = await Api.checkToken()
				//console.log('Container > Layout > checkLogin : res2', res2)
				if(res2.message === 'jwt expired'){
					navigate('/Login')
				} else {
					dispatch({
						type: 'setUser',
						payload:{
							id: res2[0].usr_id,
							user: res2[0].usr_login,
							name: res2[0].usr_nome,
							email: res2[0].usr_email,
							foto: res2[0].usr_foto,
							adm: res2[0].usr_adm,
							cal: res2[0].usr_cal,
							aux: res2[0].usr_aux
						},
					});
					//navigate('/Dashboard')
				}
			} else {
				navigate('/Login')
			}
		}
		checkLogin()
	}, [])

	return (
		<C.Container>
			{/* <C.Sider>
				<C.MenuHeader>
				</C.MenuHeader>
				<C.MenuBody>
					<SideBar/>
				</C.MenuBody>
			</C.Sider> */}
			<C.Body>
				<C.Header>
					<MainHeader/>
				</C.Header>
				<C.PageBody>
					<Outlet />
				</C.PageBody>
			</C.Body>
		</C.Container>
	);

}
