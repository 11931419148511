import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import BlockIcon from '@mui/icons-material/Block';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CheckIcon from '@mui/icons-material/Check';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const Lawyers = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [alertError, setAlertError] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)

    const [isValid, setIsValid] = useState('')
    const [managerField, setManagerField] = useState(false)

    const [stateErrors, setStateErros] = useState(false);



/// MASK ///////////////////////////////////////////////////////////////////////

  const [phoneNumber, setPhoneNumber] = useState('')

  const onChangePhone = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999']);

    setPhoneNumber(unMask(maskedValue));

  }


  const [cellNumber, setCellNumber] = useState('')

  const onChangeCell = ev => {
    
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);

    setCellNumber(unMask(maskedValue));

  }

////////////////////////////////////////////////////////////////////////////////



/// DATATABLE STATES /////////////////////////////////////////////////////////

    const [filteredList, setFilteredList] = useState([])
    const [order, setOrder] = useState('')
    const [activeOrder, setActiveOrder] = useState('')
    
    
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase()
      let newArray = list.filter((data) => {
          return data.adv_nome.toLowerCase().search(value) !== -1 
              || data.adv_escritorio.toLowerCase().search(value) !== -1 
              || data.adv_cidade.toLowerCase().search(value) !== -1 
              || data.adv_uf.toLowerCase().search(value) !== -1 
              || data.adv_tel_fixo.toLowerCase().search(value) !== -1 
              || data.adv_tel_movel.toLowerCase().search(value) !== -1 
          })
      setFilteredList(newArray);
    }

    const OrderClick = (field) => {
      setActiveOrder(field)
      let result = filteredList.sort(function(a, b){
        if (order === 'asc'){
          return a[field] < b[field] ? -1 : true
        } else {
          return a[field] > b[field] ? -1 : true
        }
      })
      order === 'asc' ? setOrder('desc') : setOrder('asc')
      setFilteredList(result)
    }
    

/// FUNCTIONS ////////////////////////////////////////////////////////////////

    const AddClick = () => {
      setModalType(1)
      setModal(true)
    }  

    const EditClick = (key) => {
      setSelectedKey([key])
      setPhoneNumber((filteredList[key].adv_tel_fixo === null || filteredList[key].adv_tel_fixo === undefined) ? '' : filteredList[key].adv_tel_fixo);
      setCellNumber((filteredList[key].adv_tel_movel === null || filteredList[key].adv_tel_movel === undefined) ? '' : filteredList[key].adv_tel_movel);
      setModalType(2)
      setModal(true)
    }

    const DelClick = (key) => {
      setSelectedKey([key])
      setModalType(3)
      setModal(true)
    }

    const ActClick = (key) => {
      setSelectedKey([key])
      setModalType(4)
      setModal(true)
    }


    const CloseModalClick = () => {
      setLoading(true)
      setSelectedKey('')
      setPhoneNumber('')
      setCellNumber('')
      setStateErros(false)
      setAlertError('')
      GetLawyer()
      setModal(false)
      setModalType('')
      reset()
      setLoading(false)
    }

      const CloseModalClick2 = () => {
      setLoading(true)
      setSelectedKey('')
      setPhoneNumber('')
      setCellNumber('')
      setStateErros(false)
      setAlertError('')
      setModal(false)
      setModalType('')
      reset()
      setLoading(false)
    }


    const GetLawyer = async () => {
      setLoading(true)
      let res = await Api.getLawyer();
      setList(res)
      setFilteredList(res)
      setLoading(false)
    }


    const delLawyers = async (id) => {
      let res = await Api.delLawyer(id);
      GetLawyer()
    }

	
    // const ActLawyers = async (id, estado) => {
    //   let res = await Api.upgLawyer({adv_id: id, adv_estado: estado})
    //   GetLawyer()
    // }


    const ActLawyers = async (id, estado) => {
      const data = ({
        adv_id: id, 
        adv_estado: estado,
        adv_usuario_atualizacao: user.id
      })
      let res = await Api.upgLawyer(data)
      GetLawyer()
    }


    // const onSubmit = async (data) => {
    //   data["adv_tel_fixo"] = phoneNumber
    //   data["adv_tel_movel"] = cellNumber

    //   if (phoneNumber === null || phoneNumber === '' || cellNumber === null || cellNumber === '') {
    //     setStateErros(true)
    //   } else {
    //     setStateErros(false)

    //   console.log(data)

    //   if (modalType === 1) {
    //     let res1 = await Api.addLawyer(data)
    //     //console.log(res1)
    //   }
    //   if (modalType === 2) {
    //     let res2 = await Api.upgLawyer(data)
    //     //console.log(res2)
    //   }

    //   CloseModalClick()
    //   GetLawyer()

    //   }
    // };



    const onSubmit = async (data) => {
      data["adv_tel_fixo"] = phoneNumber
      data["adv_tel_movel"] = cellNumber

      if (phoneNumber === null || phoneNumber === '' || cellNumber === null || cellNumber === '') {
        setStateErros(true)
      } else {
        setStateErros(false)

      switch (modalType) {
        case 1:
          const userAdd = ({
            adv_usuario_criacao: user.id,
            adv_usuario_atualizacao: user.id
          })
          const add = ({...data, ...userAdd})
          let res1 = await Api.addLawyer(add)
          //console.log("addLawyer > res1 : ", res1)
          break;

        case 2:
          const userUpg = ({
            adv_usuario_atualizacao: user.id
          })
          const upg = ({...data, ...userUpg})
          let res2 = await Api.upgLawyer(upg)
          //console.log("upgLawyer > res2 : ", res2)
          break;

        default:
          break;
      }

      CloseModalClick()
      GetLawyer()

      }
    };






/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

document.onkeydown = function (e) {
  if (e.key === 'Escape') {
    CloseModalClick();
  }
}



/// USE EFECT //////////////////////////////////////////////////////////////////

    useEffect(()=>{
      GetLawyer();
    }, [state]);


/// CONSOLE LOG ////////////////////////////////////////////////////////////////

	//console.log("filteredList : ", filteredList)




	return (
    <G.Container>

      {/* <G.Header>
        <G.HeaderTitleArea>
          <AppRegistrationOutlinedIcon fontSize='large' style={{ marginRight: "7px" }} />
          <G.MainTitle>| Cadastro</G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header> */}

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <BusinessCenterIcon style={{ fontSize: '32px',margin: '0px 10px 0px 0px' }}/>
          <G.MainTitle>Advogados</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>
      </G.Header>
        
      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>

              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'184px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_nome')}><G.DistacInfoText>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_nome') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'184px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_escritorio')}><G.DistacInfoText>Escritório</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_escritorio') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  {/* <G.FieldArea size={'160px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_email')}><G.DistacInfoText>E-mail</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_email') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea> */}
                  <G.FieldArea size={'132px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_cidade')}><G.DistacInfoText>Cidade</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_cidade') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'36px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_uf')}><G.DistacInfoText>UF</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_uf') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_tel_fixo')}><G.DistacInfoText>Telefone</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_tel_fixo') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('adv_tel_movel')}><G.DistacInfoText>Celular</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'adv_tel_movel') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                
                {(loading === false && filteredList.length === 0) && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir. ***
                  </G.Text>
                }
                
                {(loading === false && filteredList.length !== 0) &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border:  item.adv_estado === 1 ? '2px solid #2E8B57' : item.adv_estado === 0 ? '2px solid #E95839' : '2px solid #555'}}>
                          <BusinessCenterIcon style={{fontSize: '24px', color: '#000'}}/></C.ContactTypeIconArea></G.Text>
                        </G.FieldArea>
                        <G.FieldArea size={'184px'}><G.Text>{item.adv_nome}</G.Text></G.FieldArea>
                        <G.FieldArea size={'184px'}><G.Text>{item.adv_escritorio}</G.Text></G.FieldArea>
                        {/* <G.FieldArea size={'160px'}><G.Text>{item.adv_email}</G.Text></G.FieldArea> */}
                        <G.FieldArea size={'132px'}><G.Text>{item.adv_cidade}</G.Text></G.FieldArea>
                        <G.FieldArea size={'36px'}><G.Text>{item.adv_uf}</G.Text></G.FieldArea>
                        <G.FieldArea size={'112px'}>
                          <G.Text>
                            {/* {item.adv_tel_fixo} */}
                            {item.adv_tel_fixo === null ? '' : mask(item.adv_tel_fixo, ['(99) 9999-9999'])}
                          </G.Text>
                        </G.FieldArea>
                        <G.FieldArea size={'112px'}>
                          <G.Text>
                            {/* {item.adv_tel_movel} */}
                            {item.adv_tel_movel === null ? '' : mask(item.adv_tel_movel, ['(99) 9 9999-9999'])}
                          </G.Text>
                        </G.FieldArea>
                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                          <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.adv_estado === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.adv_estado === 0 ? <CheckIcon fontSize="small"/> : item.adv_estado === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}</G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>

            </G.Box>
          </>
        }
      </G.Body>


      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>


      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentSmall style={{ maxHeight: '600px' }}>

            <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].adv_estado === 0 ? '#BDF5D6' : '#ccc'), justifyContent: 'space-between', bordeRadius: '15px' }}>
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Advogado' : modalType === 2 ? 'Editar Advogado' : modalType === 3 ? 'Excluir Advogado' : (filteredList[selectedKey].adv_estado === 0 ? 'Ativar Advogado' : 'Desativar Advogado')}</C.ModalTitle>
              <G.InfoArea>
                <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
              </G.InfoArea>
            </M.ModalHeader>


            <M.ModalBody style={{flexDirection: 'column', alignItems: 'center' }}>
              
              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_id} {...register("adv_id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBody style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'  }}>
                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel>
                      Nome
                    </G.FormInputLabel>
                    <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_nome} {...register("adv_nome", { required: true })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel>
                      Escreitório
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_escritorio} {...register("adv_escritorio", { required: true })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '74.5%' }}>
                    <G.FormInputLabel>
                      OAB
                    </G.FormInputLabel>
                    <G.FormInput style={{ width: '120px', backgroundColor: '#E0E1E2' }} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_oab} {...register("adv_oab", { required: true })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel>
                      E-mail
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_email} {...register("adv_email", { required: true })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '74.5%' }}>
                    <G.FormInputLabel>
                      Telefone
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='phoneNumber'
                      placeholder=''
                      value={
                        phoneNumber === null 
                        ? '' 
                        : mask(phoneNumber, ['(99) 9999-9999'])
                      }
                      onChange={onChangePhone}
                      style={{ width: '120px' }}
                      //defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_tel_fixo} {...register("adv_tel_fixo")} 
                    />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '74.5%' }}>
                    <G.FormInputLabel>
                      Celular
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='cellNumber'
                      placeholder=''
                      value={
                        cellNumber === null 
                        ? '' 
                        : mask(cellNumber, ['(99) 9 9999-9999'])
                      }
                      onChange={onChangeCell}
                      style={{ width: '120px' }}
                      //defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_tel_movel} {...register("adv_tel_movel")} 
                    />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '100%' }}>
                    <G.FormInputLabel>
                      Cidade
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_cidade} {...register("adv_cidade", { required: true })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '49%' }}>
                    <G.FormInputLabel>
                      UF
                    </G.FormInputLabel>
                    <G.FormInput maxLength={2} style={{ width: '40px' }} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].adv_uf} {...register("adv_uf", { required: true }, { setValueAs: v => v.replace(/^[a-zA-z]+/g,"").toUpperCase() })} />
                  </G.FormInputArea>
                  
                  {(Object.keys(errors).length !== 0 || stateErrors) &&  
                    <div role="alert" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', padding: '7px', margin: '16px 0px 0px 0px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                      <WarningRoundedIcon  style={{ color: '#333', marginRight: '5px' }} />
                      {'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}
                    </div>
                  }

                </G.BoxBody>
              }
            </M.ModalBody>


            {(modalType === 3) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja excluir o Advogado:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].adv_nome}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].adv_estado === 0 ? 'ativar' : 'desativar'} o Advogado:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].adv_nome}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                // <G.Button onClick={SendLawyers} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => delLawyers(filteredList[selectedKey].adv_id) + CloseModalClick()}>Sim</G.Button>
                </>
              }
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActLawyers(filteredList[selectedKey].adv_id, filteredList[selectedKey].adv_estado === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }
            </M.ModalFooter>

          </M.ModalContentSmall>
        </M.ModalContainer>
      }

    </G.Container>
  );
}
