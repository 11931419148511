import styled from 'styled-components';

export const Container = styled.div`
background-color: #fff;
display: flex;
Width: 100%;
border-radius: 10px;
border: 1px solid #F0F0F0;
`;

export const Header = styled.div`
display: flex;
Width: 100%;
padding: 10px;
max-height: 50px;
border-bottom: 1px solid #ccc;
align-items: center;
justify-content: start;
`;
