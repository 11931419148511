import styled from 'styled-components';


export const ContactTypeArea = styled.div`
margin: 0px 10px;
display: flex;
align-items: center;
width: 170px;
justify-content: space-around;
`;

export const ContactTypeIconArea = styled.div`
padding: 3px;
width: 25px;
height: 25px;
color: #000;
font-size: 15px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-color: #fff;
`;

export const ContactTypeIcon = styled.div`
padding: 4px;
width: 15px;
height: 15px;
color: #fff;
font-size: 13px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-color: #000;
font-weight: bold;
`;

//#########################################MODAL

export const ModalContainer = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
top: 0;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: 10;
`;

export const Content = styled.div`
background-color: rgba(0, 0, 0, 0.0);
height: 550px;
width: 500px;
overflow: auto;
border-radius: 15px;
display: flex;
justify-content: start;
align-items: center;
margin-bottom: 20px;
flex-direction: column;
/* &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
&::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
} */
`;

export const ModalArea = styled.div`
background-color: #f9f9f9;
box-shadow: 0px 0px 5px #ccc;
min-height: 40px;
width: 95%;
margin-top: -3px;
border-radius: 5px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 10px;
&::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
&::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
}
`;

export const ModalTitle = styled.div`
align-items: center;
font-size: 16px;
color: #000;
display: flex;
margin-left: 15px;
font-weight: bold;
`;

export const InputArea = styled.div`
display: flex;
justify-content: start;
align-items: center;
padding: 0px;
background-color: #fff;
border-radius: 10px;
height: 40px;
margin: 5px 0px;
`;

export const InputLabel = styled.div`
align-items: center;
justify-content: center;
font-size: 11px;
color: #000;
display: flex;
width: 80px;
padding: 5px 5px;
margin-left: 5px;
border-right: 1px solid #aaa;
`;

export const Input = styled.input`
width: 250px;
font-size: 12px;
border: none;
outline: none;
background-color: #fff;
padding: 0px 10px;
font-family: inherit;
`;

export const Alert = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
background-color: #FFEEAD;
border-radius: 10px;
height: 50px;
margin: 5px 0px;
font-size: 12px;
width: 300px;
margin-right: 10px;
`;
